<template>
  <div>
    <div
      v-if="!classRoomId"
      class="d-flex justify-content-between">
      <div>
        <b-button
          variant="flat-secondary"
          class="btn-icon"
          @click="$router.push({name: `${AUTH_USER().usertype}-view-students`, params: {id: classId} })"
        >
          <feather-icon
            size="20"
            icon="ArrowLeftIcon"
          />
        </b-button>
        <span>
          {{ $t('actions.back') }}
        </span>
      </div>
    </div>
    <div class="mb-2">
      <b-button
        v-for="option of pairOptions"
        :key="option.value"
        :variant="
          selectedType === option.value ? 'primary' : 'outline-primary'
        "
        class="mr-2"
        @click="selectedType = option.value"
      >
        {{ option.text }}
      </b-button>
    </div>
    <template v-if="students.length >= 2">
      <div
        v-for="option of pairOptions"
        :key="option.value"
      >
        <make-paring
          v-show="selectedType === option.value"
          :selected-type="option.value"
          :students="students"
          :class-id="classId"
          :is-processing="isProcessing"
          :saved-groups-pairs="savedGroupsPairs"
          :saved-group-num="savedGroupNum"
          @onUpdate="updateClassPairInfo"
        />
      </div>
    </template>
    <template v-else>
      <b-card>
        {{ $t('pairing.no-two-student') }}
      </b-card>
    </template>
  </div>
</template>
<script>
import {
  BButton, BCard,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import MakeParing from './components/MakeParing.vue'

export default {
  components: {
    MakeParing,
    BButton,
    BCard,
  },
  props: {
    classRoomId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      pairOptions: [
        {
          text: i18n.tc('pairing.custom'),
          value: 'custom',
        },
        {
          text: i18n.tc('pairing.random'),
          value: 'random',
        },
      ],
      selectedType: 'custom',
      isProcessing: false,
      students: [],
      savedGroupsPairs: [],
      savedGroupNum: 2,
    }
  },
  computed: {
    classId() {
      return this.classRoomId ? this.classRoomId : this.$route.params.classId
    },
  },
  created() {
    this.getClassRoomInfo()
  },
  methods: {
    getStudentInfo(pairs) {
      const mappedPairs = []
      pairs.forEach(pair => {
        const students = JSON.parse(pair.members) ?? []
        students.forEach(studentId => {
          mappedPairs.push({
            groupId: pair.paring_code,
            groupName: pair.group_name,
            id: studentId,
          })
        })
      })
      this.savedGroupNum = pairs.length || 2
      this.savedGroupsPairs = mappedPairs
    },
    getClassRoomInfo() {
      useJwt.getParingInfoByClass(this.classId).then(response => {
        this.students = response.data.classRoom.students.map(student => ({
          name: `${student.firstname} ${student.lastname}`,
          id: Number(student.id),
        }))
        this.getStudentInfo(response.data.pairs)
      })
    },
    updateClassPairInfo(pairs) {
      const groups = [...new Set(pairs.map(pair => pair.groupId))]
      const formattedPairs = []
      this.isProcessing = true
      groups.forEach(group => {
        formattedPairs.push({
          groupId: group,
          groupName: pairs.find(grp => grp.groupId === group)?.groupName,
          students: pairs.filter(grp => grp.groupId === group).map(grp => grp.id),
        })
      })
      useJwt.storeParingInfoByClass({
        classId: this.classId,
        details: formattedPairs,
      }).then(response => {
        this.showSuccessMessage(response)
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
