<template>
  <div>
    <b-modal
      :visible="showClassroomDetailModal"
      :title="formatClassName(room.name)"
      hide-footer
      @close="$emit('closeClassroomDetailModal')"
      @ok="$emit('closeClassroomDetailModal')"
      @hide="$emit('closeClassroomDetailModal')"
    >
      <b-list-group class="student-friendly-list shadow-sm rounded-lg">
        <!-- Total Students -->
        <b-list-group-item class="py-2 border-left-primary hover-lighten">
          <b-row align-v="center">
            <b-col cols="6"
                   class="label-column"
            >
              <div class="d-flex align-items-center">
                <div class="icon-wrapper bg-primary-light rounded-circle mr-3">
                  <feather-icon icon="UsersIcon"
                                class="text-primary"
                  />
                </div>
                <span class="font-weight-bold">
                  {{ $t("student-report-module.total-students") }}
                </span>
              </div>
            </b-col>
            <b-col cols="6"
                   class="value-column"
            >
              <b-badge variant="primary"
                       pill
                       class="px-2 py-2 badge-glow"
              >
                {{ totalStudents ? totalStudents : "0" }}
              </b-badge>
            </b-col>
          </b-row>
        </b-list-group-item>

        <!-- Teachers -->
        <b-list-group-item v-if="!hideTeacher"
                           class="py-3 border-left-info hover-lighten"
        >
          <b-row align-v="center">
            <b-col cols="6"
                   class="label-column"
            >
              <div class="d-flex align-items-center">
                <div class="icon-wrapper bg-info-light rounded-circle mr-3">
                  <feather-icon icon="UserIcon"
                                class="text-info"
                  />
                </div>
                <span class="font-weight-bold">
                  {{ $t("class-module.teachers") }}
                </span>
              </div>
            </b-col>
            <b-col cols="6"
                   class="value-column"
            >
              <span v-if="room.teacher"
                    class="font-weight-medium"
              >
                {{ room.teacher.firstname + ' ' + room.teacher.lastname }}
              </span>
              <!-- Request Teacher -->
              <div v-if="isAStudent && canRequestTeacher">
                <b-overlay :show="requestProcessing"
                           bg-color="dark"
                >
                  <b-button
                    size="sm"
                    variant="outline-info"
                    class="mt-1 animated-button"
                    @click="$emit('requestTeacher')"
                  >
                    <feather-icon icon="SendIcon"
                                  size="14"
                                  class="mr-1"
                    />
                    {{ $t('class-module.request-teacher') }}
                  </b-button>
                </b-overlay>
              </div>
            </b-col>
          </b-row>
        </b-list-group-item>

        <!-- Co-Teachers -->
        <b-list-group-item v-if="room.teachers && !hideCoTeacher"
                           class="py-3 border-left-success hover-lighten"
        >
          <b-row align-v="center">
            <b-col cols="6"
                   class="label-column"
            >
              <div class="d-flex align-items-center">
                <div class="icon-wrapper bg-success-light rounded-circle mr-3">
                  <feather-icon icon="UsersIcon"
                                class="text-success"
                  />
                </div>
                <span class="font-weight-bold">
                  {{ $t("class-module.co-teachers") }}
                </span>
              </div>
            </b-col>
            <b-col cols="6"
                   class="value-column"
            >
              <span v-if="room.teachers.length"
                    class="font-weight-medium"
              >
                <b-avatar-group size="sm"
                                class="mb-1"
                >
                  <b-avatar
                    v-for="teacher in ARRAY_UNIQUE_BY_KEY(room.teachers, 'id').slice(0,3)"
                    :key="teacher.id"
                    :text="teacher.firstname[0] + teacher.lastname[0]"
                    variant="success"
                    class="mr-1"
                  />
                  <b-avatar v-if="ARRAY_UNIQUE_BY_KEY(room.teachers, 'id').length > 3"
                            text="+"
                            variant="light-success"
                  />
                </b-avatar-group>
                <div>
                  <span
                    v-for="(teacher, index) in ARRAY_UNIQUE_BY_KEY(room.teachers, 'id')"
                    :key="teacher.id"
                  >
                    <span>
                      {{ teacher.id == self.id ? $t('you') : teacher.firstname + ' ' + teacher.lastname }}
                    </span>
                    <span v-if="index !== room.teachers.length - 1">, </span>
                  </span>
                </div>
              </span>
              <span v-else
                    class="text-danger font-weight-medium"
              >
                {{ $t('class-module.no-teachers-assigned') }}
              </span>
            </b-col>
          </b-row>
        </b-list-group-item>

        <!-- Class Owner -->
        <b-list-group-item class="py-3 border-left-warning hover-lighten">
          <b-row align-v="center">
            <b-col v-if="!hideOwner && room.owner && !isAStudent"
                   cols="6"
                   class="label-column"
            >
              <div class="d-flex align-items-center">
                <div class="icon-wrapper bg-warning-light rounded-circle mr-3">
                  <feather-icon icon="UserIcon"
                                class="text-warning"
                  />
                </div>
                <span class="font-weight-bold">
                  {{ $t("class-module.class-table.owner") }}
                </span>
              </div>
            </b-col>
            <b-col cols="6"
                   class="value-column"
            >
              <span class="font-weight-medium">
                {{
                  room.owner.id == self.id ? $t('you') : room.owner.firstname + " " + room.owner.lastname
                }}
              </span>
            </b-col>
          </b-row>
        </b-list-group-item>

        <!-- School -->
        <b-list-group-item class="py-3 border-left-danger hover-lighten">
          <b-row align-v="center">
            <b-col cols="6"
                   class="label-column"
            >
              <div class="d-flex align-items-center">
                <div class="icon-wrapper bg-danger-light rounded-circle mr-3">
                  <feather-icon icon="BookOpenIcon"
                                class="text-danger"
                  />
                </div>
                <span class="font-weight-bold">
                  {{ $t("class-module.class-table.school") }}
                </span>
              </div>
            </b-col>
            <b-col cols="6"
                   class="value-column"
            >
              <span class="font-weight-medium">
                {{ room.school.firstname }} {{ room.school.lastname }}
              </span>
            </b-col>
          </b-row>
        </b-list-group-item>

        <!-- Dates Section -->
        <b-list-group-item class="py-3 border-left-purple hover-lighten">
          <b-row align-v="center">
            <b-col cols="6"
                   class="label-column"
            >
              <div class="d-flex align-items-center">
                <div class="icon-wrapper bg-purple-light rounded-circle mr-3">
                  <feather-icon icon="CalendarIcon"
                                class="text-purple"
                  />
                </div>
                <span class="font-weight-bold">
                  {{ $t("class-module.start-date") }}
                </span>
              </div>
            </b-col>
            <b-col cols="6"
                   class="value-column"
            >
              <div class="date-chip">
                <feather-icon icon="PlayCircleIcon"
                              size="14"
                              class="mr-1 text-success"
                />
                <span class="font-weight-medium">
                  {{ room.created_at ? FORMAT_DATE(room.created_at, 'YYYY-MM-DD') : "N/A" }}
                </span>
              </div>
              <div v-if="room.completed_at"
                   class="date-chip mt-2"
              >
                <feather-icon icon="CheckCircleIcon"
                              size="14"
                              class="mr-1 text-danger"
                />
                <span class="font-weight-medium">
                  {{ FORMAT_DATE(room.completed_at, 'YYYY-MM-DD') }}
                </span>
              </div>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow, BCol,
  BModal, BBadge,
  BOverlay,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { getUserData } from '@/auth/utils'
import {
  USER_TYPE_STUDENT,
} from '@/const/userType'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BBadge,
    BOverlay,
    BListGroup,
    BListGroupItem,
  },
  props: {
    showClassroomDetailModal: {
      type: Boolean,
      default: false,
    },
    hideClassroomListButton: {
      type: Number,
      default: 0,
    },
    room: {
      type: Object,
      default: () => {},
    },
    courses: {
      type: Array,
      default: () => [],
    },
    overAllCourseCompletion: {
      type: Number,
      default: 0.0,
    },
    totalStudents: {
      type: Number,
      default: 0,
    },
    hideTeacher: {
      type: Number,
      default: 0,
    },
    hideCoTeacher: {
      type: Number,
      default: 0,
    },
    hideSchool: {
      type: Number,
      default: 0,
    },
    hideOwner: {
      type: Number,
      default: 0,
    },
    requestProcessing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      self: getUserData(),
    }
  },
  computed: {
    ...mapGetters('appConfig', ['getSchoolSettings', 'getDistrictSettings']),
    courseProgress() {
      const value = this.overAllCourseCompletion
      if (value >= 25.0 && value <= 50.0) { return 'warning' }
      if (value > 50 && value <= 75.0) {
        return 'primary'
      }
      if (value > 75.0) {
        return 'success'
      }
      return 'danger'
    },
    isAStudent() {
      return this.self.usertype === USER_TYPE_STUDENT
    },
    noOfStudentSettings() {
      return {
        distNoOfStudentInfo: this.getDistrictSettings?.find(metaValue => metaValue.key === 'show_no_of_students')?.value ?? '0',
        schoolNoOfStudentInfo: this.getSchoolSettings?.find(metaValue => metaValue.key === 'show_no_of_students')?.value ?? '0',
      }
    },
    hideNoOfStudent() {
      return this.isAStudent && (this.noOfStudentSettings?.distNoOfStudentInfo === '1' || this.noOfStudentSettings?.schoolNoOfStudentInfo === '1')
    },
    isFullAccessWithTutorUser() {
      return this.self.user_subscription_type === 'external_full_access_tutor' || !this.self.user_subscription_type
    },
    canRequestTeacher() {
      return this.isFullAccessWithTutorUser && ((!this.room.teacher || this.room.teacher.lastname.includes('default')) && this.room.reservation_id)
    },
  },
  methods: {
    formatClassName(name) {
      return name?.split('#')[0]
    },
    backToClassRoom() {
      if (window.PREVIOUS_ROUTE_PATH && window.PREVIOUS_ROUTE_PATH.includes('?page')) {
        this.GO_BACK()
      } else {
        this.$router.push({ name: `${this.AUTH_USER().usertype}-classes` })
      }
    },
  },
}
</script>
<style lang="scss">
.classroom-title {
    span {
        font-size: 18px;
        color: #104373;
    }
    .list-class-btn {
        margin-top: -4px;
        width:22px;
        height:22px;
    }
}
@media screen and (max-width: 576px) {
    .classroom-title {
      span {
        font-size: 16px;
        color: #104373;
      }
      .list-class-btn {
        width:22px  !important;
        height:22px;
      }
    }
}
</style>

<style scoped>
.student-friendly-list {
  border-radius: 12px;
  overflow: hidden;
}

.student-friendly-list .b-list-group-item {
  transition: all 0.2s ease;
}

.hover-lighten:hover {
  background-color: rgba(0, 0, 0, 0.03);
  transform: translateY(-2px);
}

.border-left-primary {
  border-left: 4px solid #7367F0;
}

.border-left-info {
  border-left: 4px solid #00CFE8;
}

.border-left-success {
  border-left: 4px solid #28C76F;
}

.border-left-warning {
  border-left: 4px solid #FF9F43;
}

.border-left-danger {
  border-left: 4px solid #EA5455;
}

.border-left-purple {
  border-left: 4px solid #9C27B0;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
}

.bg-primary-light {
  background-color: rgba(115, 103, 240, 0.12);
}

.bg-info-light {
  background-color: rgba(0, 207, 232, 0.12);
}

.bg-success-light {
  background-color: rgba(40, 199, 111, 0.12);
}

.bg-warning-light {
  background-color: rgba(255, 159, 67, 0.12);
}

.bg-danger-light {
  background-color: rgba(234, 84, 85, 0.12);
}

.bg-purple-light {
  background-color: rgba(156, 39, 176, 0.12);
}

.badge-glow {
  box-shadow: 0 0 10px rgba(115, 103, 240, 0.5);
}

.value-column {
  font-size: 1.05rem;
}

.date-chip {
  display: inline-flex;
  align-items: center;
  background: #f8f8f8;
  border-radius: 20px;
  padding: 4px 12px;
}

.text-purple {
  color: #9C27B0;
}

.animated-button {
  transition: all 0.2s;
}

.animated-button:hover {
  transform: scale(1.05);
}
</style>
