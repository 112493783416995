<template>
  <div>
    <b-row>
      <!-- Back Button -->
      <b-col
        v-if="!classRoomId"
        md="4"
      >
        <b-button
          class="mb-1"
          variant="transparent"
          @click="GO_BACK()"
        >
          <feather-icon
            icon="ArrowLeftIcon"
          />
          {{ $t('actions.back') }}
        </b-button>
      </b-col>

      <b-col
        v-if="!isAStudent"
        md="8"
      >
        <b-row
          class="justify-content-between align-items-center"
        >
          <!-- Filter Classroom Students -->
          <b-col md="6">
            <b-form-group>
              <v-select
                v-model="selectedClass"
                :options="searchRooms"
                :filterable="false"
                :reduce="(room) => room.id"
                :disabled="isLoading"
                placeholder="Search Students by Classroom"
                class="m-md"
                label="name"
                @search="searchClasses"
              />
            </b-form-group>
          </b-col>
        </b-row>

      </b-col>
    </b-row>

    <b-row>
      <b-col md="3">
        <!-- Student List -->
        <b-card
          id="students-list"
          class="mb-0"
          body-class="px-1 pt-1 pb-1"
        >
          <b-card-header class="p-0">
            <h4>{{ $t('labels.student-list') }}</h4>
          </b-card-header>
          <b-overlay :show="isLoading"
                     class="pb-0"
          >
            <b-card
              v-for="student in studentsList"
              :key="student.id"
              border-variant="secondary"
              :bg-variant="selectedStudent.id === student.id ? 'primary' : ''"
              :text-variant="selectedStudent.id === student.id ? 'light' : 'dark'"
              class="p-0 mb-1 cursor-pointer"
              body-class="p-1"
              :class="{ active: selectedStudent.id === student.id }"
              @click="setSelectedStudent(student)"
            >
              <p class="mb-0">
                {{ `${student.name}` }}
                <b-badge v-if="getEssayCount(student.id) > 0"
                         variant="light-danger"
                         class="text-right"
                >
                  {{ getEssayCount(student.id) }}
                </b-badge>
              </p>
            </b-card>
          </b-overlay>
        </b-card>
      </b-col>

      <b-col md="9">
        <b-overlay :show="isGettingEssay">
          <essay-lists
            :student-essay="studentEssay"
            :student-id="selectedStudent ? selectedStudent.id: 0"
          />
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BOverlay, BButton, BFormGroup, BBadge,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import useApollo from '@/@core/graphql/useApollo'
import { getUserData } from '@/auth/utils'
import { USER_TYPE_SCHOOL, USER_TYPE_STUDENT, USER_TYPE_PARENT } from '@/const/userType'
import vSelect from 'vue-select'
import EssayLists from './components/EssayLists.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BOverlay,
    BButton,
    BFormGroup,
    EssayLists,
    BBadge,
    vSelect,
  },
  props: {
    classRoomId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      self: getUserData(),
      students: null,
      isLoading: false,
      selectedStudent: null,
      isProcessing: false,
      selectedClass: null,
      searchRooms: [],
      filteredStudents: [],
      studentEssay: [],
      isGettingEssay: false,
      essayCounts: [],
    }
  },
  computed: {
    isASchool() {
      return this.self.usertype === USER_TYPE_SCHOOL
    },
    isAStudent() {
      return this.self.usertype === USER_TYPE_STUDENT
    },
    isAParent() {
      return this.self.usertype === USER_TYPE_PARENT
    },
    studentsList() {
      return this.selectedClass ? this.filteredStudents : this.students
    },
    classId() {
      return this.classRoomId ? this.classRoomId : this.$route.params.classId
    },
  },
  watch: {
    selectedClass() {
      this.getStudentsByClass()
      if (!this.filteredStudents.length) {
        [this.c] = this.studentsList
      }
    },
    selectedStudent: {
      handler(newValue) {
        if (newValue) {
          this.getStudentEssay()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.searchClasses('')
    if (this.classId) {
      this.selectedClass = this.classId
      this.getStudentsByClass()
    } else {
      this.getStudents()
    }
  },
  methods: {
    getEssayCount(userId) {
      return this.essayCounts.filter(item => item.user_id === userId).length
    },
    getStudentEssay() {
      if (this.isGettingEssay) {
        return
      }
      this.isGettingEssay = true
      useJwt.getStudentClassroomEssays(this.selectedClass, {
        params: {
          user_id: this.selectedStudent.id,
        },
      }).then(response => {
        this.studentEssay = response.data?.data?.essay
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isGettingEssay = false
      })
    },
    getEssayCounts() {
      useJwt.getEssayCounts(this.classId).then(response => {
        this.essayCounts = response.data.data
      })
    },
    getPairedStudents(filtered = false) {
      useJwt.getStudentPaired({
        params: {
          class_id: this.selectedClass,
        },
      }).then(response => {
        const res = response.data.data
        res.push({
          firstname: this.self.firstname,
          lastname: this.self.lastname,
          id: this.self.id,
        })
        if (filtered) {
          this.filteredStudents = res.map(student => ({
            id: student.id,
            name: `${student?.firstname} ${student?.lastname}`,
          }))
          /* eslint-disable prefer-destructuring */
          this.selectedStudent = this.filteredStudents?.[0] || null
        } else {
          this.students = res.map(student => ({
            id: student.id,
            name: `${student?.firstname} ${student?.lastname}`,
          }))
          /* eslint-disable prefer-destructuring */
          this.selectedStudent = this.students?.[0] || null
        }
      }).finally(() => { this.isLoading = false })
    },
    // eslint-disable-next-line consistent-return
    getStudents() {
      this.isLoading = true
      const params = {
        perPage: 999,
        usertype: 'student',
      }
      if (this.classId) {
        this.getEssayCounts()
      }
      if (this.isAStudent) {
        return this.getPairedStudents()
      }
      useJwt.getUsers({ params })
        .then(response => {
          const res = response.data
          this.students = res.data?.map(student => ({
            id: student.id,
            name: student.fullname,
          }))
          /* eslint-disable prefer-destructuring */
          this.selectedStudent = this.studentsList[0]
        })
        .finally(() => { this.isLoading = false })
    },
    setSelectedStudent(student) {
      this.selectedStudent = student
    },
    searchClasses(value) {
      useApollo
        .searchRooms(value, this.self.id)
        .then(res => {
          this.searchRooms = res.data.searchRooms.map(room => ({
            ...room,
            id: Number(room.id), // Convert ID to a number
          }))
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
    },
    // eslint-disable-next-line consistent-return
    getStudentsByClass() {
      this.isLoading = true
      this.filteredStudents = []
      if (this.classId) {
        this.getEssayCounts()
      }
      if (this.isAStudent) {
        return this.getPairedStudents(true)
      }
      useJwt.getStudentsOfClass(this.selectedClass, {
        params: {
          onlyParent: this.isAParent ? 'true' : null,
        },
      }).then(response => {
        this.filteredStudents = response.data?.data.map(student => ({
          id: student.id,
          name: student.fullname,
        }))
        if (this.filteredStudents.length) [this.selectedStudent] = this.studentsList
        else this.isLoading = false
      }).finally(() => { this.isLoading = false })
    },
  },
}
</script>

<style scoped>
#students-list {
  max-height: 90vh;
  overflow-y: auto;
}
.b-overlay-wrap {
  padding-bottom: 18px;
}
.dark-layout .btn-transparent {
  color: #c6c6c6;
}
</style>
