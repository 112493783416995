<template>
  <b-overlay :show="isFetchingStudentStats">
    <b-row>
      <!-- Sidebar Menu starts -->
      <b-col
        :md="showFullSidebar ? 2 : 1"
        sm="12"
        :class="{'mb-1': isNarrowDevice}"
      >
        <b-card
          class="mb-1 side-card"
          :class="{'side-card-minimal': !showFullSidebar}"
          body-class="p-md-0 p-1"
        >
          <div
            class="class-side-section"
            :class="{'p-0': isNarrowDevice}"
          >
            <ul class="nav navbar-nav mb-md-1">
              <li
                class="nav-item d-flex w-100"
                :class="showFullSidebar ? 'justify-content-between' : 'justify-content-center'"
              >
                <b-row class="w-100 align-items-center">
                  <b-col
                    :md="showFullSidebar ? 11 : 12"
                    sm="12"
                    :class="showFullSidebar ? 'pr-0' : 'text-center'"
                  >
                    <div
                      class="side-classname d-flex w-100"
                      :class="{'mx-1': isNarrowDevice, 'justify-content-center': !showFullSidebar}"
                    >
                      <feather-icon
                        icon="MenuIcon"
                        size="21"
                        class="d-xl-none d-md-none mr-2"
                        style="margin-right: 10px"
                        @click="showMobileSidebar = !showMobileSidebar"
                      />

                      <div class="d-flex">
                        <feather-icon
                          v-b-tooltip.hover="formatClassName(room.name)"
                          class="cursor-pointer d-none d-lg-block"
                          icon="HomeIcon"
                          :size="showFullSidebar ? '18': '26'"
                          @click="showClassroomDetailModal = true"
                        />
                        <h5
                          v-if="showFullSidebar"
                          class="cursor-pointer mb-0 ml-1"
                          @click="showClassroomDetailModal = true"
                        >
                          {{ formatClassName(room.name) }}
                        </h5>
                      </div>
                    </div>
                  </b-col>

                  <b-col
                    class="d-none d-md-block text-center px-0"
                    :class="{ 'mt-1': !showFullSidebar }"
                    :md="showFullSidebar ? 1 : 12"
                  >
                    <b-badge
                      pill
                      variant="light"
                      class="cursor-pointer"
                      @click="toggleSidebar"
                    >
                      <feather-icon
                        v-b-tooltip.hover="showFullSidebar ? 'Collapse' : 'Expand'"
                        :icon="showFullSidebar ? 'Minimize2Icon' : 'Maximize2Icon'"
                        class="text-primary"
                        :style="{ rotate: '45deg' }"
                        size="18"
                      />
                    </b-badge>
                  </b-col>
                </b-row>
              </li>
            </ul>
            <div
              :class="{ 'd-flex align-items-center': showFullSidebar }"
            >
              <div
                v-if="room.link"
                class="text-success"
                :class="{ 'text-center': !showFullSidebar }"
              >
                <feather-icon
                  v-b-tooltip.hover="$t('labels.live-link')"
                  class="blinking-icon cursor-pointer"
                  :class="{ 'mr-1': showFullSidebar }"
                  icon="RadioIcon"
                  :size="showFullSidebar ? '14' : '20'"
                  @click="
                    openClassLink
                  "
                />
              </div>
              <div
                v-if="courses.length > 0"
                class="course-progress-bar w-100"
              >
                <div class="bar">
                  <b-progress
                    v-b-tooltip.bottom.hover="`${$t('class-module.course-completion')}: ${overAllCourseCompletion}`"
                    style="width: 100%; height:15px"
                    :max="100"
                  >
                    <b-progress-bar :variant="courseProgress"
                                    :value="overAllCourseCompletion"
                    >
                      <strong>{{ overAllCourseCompletion }} </strong>
                    </b-progress-bar>
                  </b-progress>
                <!-- <span>{{ $t('class-module.course-completion') }}</span> -->
                </div>
              </div>
            </div>

            <hr
              v-if="!isNarrowDevice || showMobileSidebar"
            >
            <!-- class details -->
            <class-detail-head
              :show-classroom-detail-modal="showClassroomDetailModal"
              :hide-classroom-list-button="hideClassroomListButton"
              :room="room"
              :courses="courses"
              :over-all-course-completion="overAllCourseCompletion"
              :total-students="totalStudents"
              :hide-teacher="hideTeacher"
              :hide-co-teacher="hideCoTeacher"
              :hide-school="hideSchool"
              :hide-owner="hideOwner"
              :request-processing="requestProcessing"
              @requestTeacher="requestTeacher"
              @closeClassroomDetailModal="showClassroomDetailModal = false"
            />
            <div
              v-if="!isNarrowDevice || showMobileSidebar"
              class="student-table-nav"
            >
              <template v-if="activeClassTab ==='student-management'">
                <v-select
                  v-if="!hideStudentCards && showFullSidebar"
                  v-model="filterStudent"
                  :options="searchUser"
                  :filterable="true"
                  :reduce="(fullname) => fullname.id"
                  class="pl-0 mb-1"
                  label="fullname"
                  :placeholder="$t('labels.type-to-search')"
                  :filter="studentDropdownFilter"
                >
                  <template #option="{ fullname, email }">
                    {{ fullname }}
                    <br>
                    <small>{{ email }}</small>
                  </template>
                </v-select>
                <div v-if="isHighLevelUser || isATeacher"
                     class="d-flex mb-0 class-switcher"
                >
                  <div
                    class="d-flex align-items-center"
                    :class="{ 'flex-column': !showFullSidebar }"
                    style="gap:5px;"
                  >
                    <span v-if="showFullSidebar">{{ $t("class-module.self-study-mode") }}</span>
                    <div v-else>
                      <feather-icon
                        v-b-tooltip.hover.left="$t('class-module.self-study-mode')"
                        icon="BookOpenIcon"
                        size="20"
                      />
                    </div>
                    <div class="d-flex">
                      <b-form-checkbox
                        v-model="selfStudyClassRoom"
                        variant="success"
                        switch
                        value="1"
                        unchecked-value="0"
                        @change="updateSelfStudyClassRoom"
                      />
                      <feather-icon
                        v-b-tooltip.hover.left="$t('class-module.self-study-info')"
                        class="cursor-pointer text-primary"
                        icon="InfoIcon"
                        size="20"
                      />
                    </div>
                  </div>
                </div>
                <hr v-if="(isHighLevelUser || isATeacher) && !showFullSidebar">
              </template>
              <StudentTableNavV2
                :room="room"
                :is-high-level-user="isHighLevelUser"
                :is-a-teacher="isATeacher"
                :is-a-student="isAStudent"
                :is-processing="isProcessing"
                :class-id="classId"
                :has-class-test="!!todayClassTests.length"
                :is-a-class-conductor="isAClassConductor"
                :is-self-study="selfStudyClassRoom==1"
                :hide-class-test-setting="hideClassTest"
                :hide-view-essay="hideViewEssay"
                :hide-show-script="hideShowScript"
                :nav-type="activeClassTab"
                :is-a-class-teacher="isAClassTeacher"
                :show-full-sidebar="showFullSidebar"
                :active-nav="classManagementSide"
                @showClassStats="showClassStats"
                @showGroupReportModal="showGroupReportModal = true"
                @viewClassCalendar="viewClassCalendar(room.id)"
                @showNotification="showNotification"
                @showClassRubrics="showClassRubrics = true"
                @showAddStudentsModal="$bvModal.show('modal-add-students')"
                @showClassSettings="showClassSettingsModal = 'class-setting'"
                @showClassManagementSide="value => showClassManagementSide(value)"
              />
              <template v-if="activeClassTab ==='student-management'">
                <b-button
                  v-if="isTrailUser"
                  variant="success"
                  role="button"
                  :disabled="isUpdatingToPaid"
                  class="badge-glow mb-1"
                  @click="updateToPaid('full_access')"
                >
                  <b-spinner v-if="isUpdatingToPaid"
                             small
                  />  Upgrade to Full Access
                </b-button>
                <b-button
                  v-if="isTrailUser || isFullAccessUser"
                  variant="success"
                  role="button"
                  :disabled="isUpdatingToPaid"
                  class="badge-glow"
                  @click="updateToPaid('full_access_tutor')"
                >
                  <b-spinner
                    v-if="isUpdatingToPaid"
                    small
                  />  Upgrade to Tutor Support
                </b-button>
              </template>
            </div>
          </div>
        </b-card>
      </b-col>
      <!-- Sidebar Menu ends -->

      <b-col
        class="pl-md-0"
        :md="showFullSidebar ? 10 : 11"
      >
        <!-- Nav Menu starts -->
        <b-nav
          align="left"
          class="w-100 class-nav shadow"
          :class="{'justify-content-between': isNarrowDevice}"
          :style="{ height: '52px' }"
          pills
        >
          <b-nav-item
            class="font-weight-bold"
            :active="activeClassTab === 'class-management'"
            @click="showClassTab('class-management')"
          >
            {{ $t('class-module.class-management') }}
          </b-nav-item>

          <b-nav-item
            class="font-weight-bold"
            :active="activeClassTab === 'student-management'"
            @click="showClassTab('student-management')"
          >
            {{ $t('class-module.students') }}
          </b-nav-item>

          <b-nav-item
            class="font-weight-bold"
            :active="activeClassTab === 'report-management'"
            @click="showClassTab('report-management')"
          >
            {{ $t('class-module.reports') }}
          </b-nav-item>
        </b-nav>
        <!-- Nav Menu ends -->

        <div class="pl-md-0">
          <!-- Class Management starts -->
          <template v-if="activeClassTab === 'class-management'">
            <template v-if="!isRouterView || $route.name.endsWith('-view-students')">
              <!-- assignment tabs -->
              <template v-if="classManagementSide === 'assignment'">
                <div class="d-flex mb-1 assignment-tab">
                  <b-button
                    :variant="activeAssignmentTab == 'homework' ? 'success' : 'outline-success' "
                    @click="activeAssignment('homework')"
                  >
                    {{ $t('homework') }}
                  </b-button>
                  <b-button
                    v-if="!!todayClassTests.length"
                    :variant="activeAssignmentTab == 'class-test' ? 'danger' : 'outline-danger' "
                    @click="activeAssignment('class-test')"
                  >
                    {{ $t('student-diary-module.class-test') }}
                  </b-button>
                  <b-button
                    v-if="followUpCourses.length > 0"
                    :variant="activeAssignmentTab == 'follow-ups' ? 'warning' : 'outline-warning' "
                    @click="activeAssignment('follow-ups')"
                  >
                    {{ $t('courses-module.follow-ups') }}
                  </b-button>
                  <b-button
                    v-if="!hideViewEssay"
                    :variant="activeAssignmentTab == 'essay' ? 'primary' : 'outline-primary' "
                    @click="activeAssignment('essay')"
                  >
                    {{ $t('student-diary-module.view-essay') }}
                  </b-button>
                  <b-button
                    v-if="hasVocabLesson"
                    :variant="activeAssignmentTab == 'vocab-lesson' ? 'info' : 'outline-info' "
                    @click="activeAssignment('vocab-lesson')"
                  >
                    Vocab Lesson
                  </b-button>
                </div>
                <!-- assignment tabs -->
                <b-row>

                  <b-col
                    md="12"
                    class="assignment-filters"
                  >
                    <template v-if="activeAssignmentTab === 'homework'">
                      <b-tabs v-model="activeTab">
                        <b-tab
                          v-for="courseDetail,index in courseByProgress"
                          :key="index"
                        >
                          <template #title>
                            {{ capitalizeFirstLetter(courseDetail.title) }}
                            <b-badge
                              class="btn-rounded"
                              :variant="index === 0 ? 'light-primary': 'light-success'"
                            >
                              {{ courseDetail.data.length }}
                            </b-badge>
                          </template>
                          <template v-if="courseDetail.data.length">
                            <b-row>
                              <b-col
                                v-for="course in courseDetail.data"
                                :key="course.id"
                                md="2"
                                cols="6"
                                class="mb-1"
                              >
                                <b-card
                                  :id="`course-btn-${course.course_id}`"
                                  class="course-card border mb-1 h-100"
                                  body-class="pb-0 cursor-pointer"
                                  @click.stop="viewLessons(course.course_id, classId)"
                                >
                                  <b-row>
                                    <b-col>
                                      <vue-apex-charts
                                        :key="course.id"
                                        style="margin-top:-20px;"
                                        :options="chartOptions(course.lesson_completion)"
                                        :series="[course.lesson_completion]"
                                      />
                                      <div class="text-center">
                                        <h5 class="mt-1">
                                          {{ course.course_name }}
                                        </h5>
                                        <p class="mb-0">
                                          {{ course.total_homework }} {{ $t('actions.lessons') }}
                                        </p>
                                      </div>
                                    </b-col>

                                  </b-row>
                                </b-card>
                              </b-col>
                            </b-row>

                          </template>
                          <template v-else>
                            <b-card>
                              <h6>
                                {{ $t('courses-module.no-courses-available') }}
                              </h6>
                            </b-card>

                          </template>
                        </b-tab>
                      </b-tabs>
                    </template>
                    <template v-if="activeAssignmentTab === 'follow-ups'">
                      <template v-if="followUpCourses.length">
                        <b-row>
                          <b-col
                            v-for="followUpCourse in followUpCourses"
                            :key="followUpCourse.id"
                            md="4"
                            class="pr-md-0"
                          >
                            <b-card
                              :id="`course-btn-${followUpCourse.course_id}`"
                              class="course-card border mb-1"
                              body-class="p-1 cursor-pointer"
                              :disabled="isAParent"
                              @click.stop="viewLessons(followUpCourse.course_id, classId, 'follow_up')"
                            >
                              <h5>{{ followUpCourse.course_name }}</h5>
                            </b-card>
                          </b-col>
                        </b-row>
                      </template>
                      <template v-else>
                        <b-card>
                          <h6>
                            {{ $t('courses-module.no-follow-up') }}
                          </h6>
                        </b-card>

                      </template>
                    </template>
                    <template v-if="activeAssignmentTab === 'class-test' && !!todayClassTests.length">
                      <template v-if="todayClassTests.length">
                        <b-tabs v-model="activeTab">
                          <b-tab v-for="todayClassTest of todayClassTests"
                                 :key="todayClassTest.id"
                                 :title="capitalizeFirstLetter(todayClassTest.name) "
                          >
                            <b-row>
                              <b-col
                                v-for="lesson in todayClassTest.lessons"
                                :key="lesson.id"
                                md="4"
                              >
                                <b-card
                                  :id="`course-btn-${lesson.id}`"
                                  class="course-card border mb-1"
                                  body-class="p-1 cursor-pointer"
                                  @click="openTestLesson(lesson, todayClassTest.event_id)"
                                >
                                  <b-row class="align-items-start justify-content-left">
                                    <b-col>
                                      {{ lesson.name }}
                                    </b-col>
                                    <b-col
                                      v-if="lesson.image"
                                      cols="4"
                                    >
                                      <b-avatar
                                        :src="lesson.image"
                                        :rounded="true"
                                        size="50px"
                                      />
                                    </b-col>
                                  </b-row>

                                </b-card>
                              </b-col>
                            </b-row>
                          </b-tab>
                        </b-tabs>
                      </template>
                      <template v-else>
                        <b-card>
                          <h6>
                            {{ $t('courses-module.no-follow-up') }}
                          </h6>
                        </b-card>
                      </template>
                    </template>
                    <template
                      v-if="activeAssignmentTab === 'essay'"
                    >
                      <class-essay
                        :class-room-id="Number(classId)"
                      />
                    </template>
                    <template
                      v-if="activeAssignmentTab === 'vocab-lesson'"
                    >
                      <vocab-lesson
                        :class-room-id="Number(classId)"
                      />
                    </template>
                    <template v-if="viewLessonGroup">
                      <student-lesson
                        :selected-course-id="selectedCourseId"
                        :class-room-id="Number(classId)"
                        :selected-course-type="selectedCourseType"
                        @showLesson="showLesson"
                        @back="activeAssignment('homework')"
                      />
                    </template>
                    <template v-if="viewLesson">
                      <lesson
                        :class-room-id="Number(classId)"
                        :selected-lesson-id="selectedLessonId"
                        :selected-event-id="selectedEventId"
                        :selected-mode="selectedMode"
                        @back="() => { viewLessonGroup = true; viewLesson = false; }"
                      />
                    </template>
                  </b-col>
                </b-row>
              </template>
              <template v-else-if="classManagementSide === 'show-instruction'">
                <b-card>
                  <class-script
                    :show="true"
                    :class-id="Number(classId)"
                    :is-high-level-user="isHighLevelUser || isATeacher"
                    :with-modal="false"
                    :is-class-room="true"
                  />
                </b-card>
              </template>
              <template v-else-if="classManagementSide === 'game-settings'">
                <b-card>
                  <game-settings-modal :self="self" />
                </b-card>
              </template>
              <!-- Handle With Routes-->
              <template v-if="classManagementSide === 'attendance'">
                <attendance
                  :class-id="Number(classId)"
                  :students="studentWithStat"
                />
              </template>
              <template v-if="classManagementSide === 'compose-email'">
                <notification
                  :class-room-id="Number(classId)"
                />
              </template>
              <template v-if="classManagementSide === 'calendar-event'">
                <Calendar
                  :class-room-id="Number(classId)"
                />
              </template>
              <template v-if="classManagementSide === 'class-test'">
                <class-test
                  :class-room-id="Number(classId)"
                  @openTestLesson="openTestLesson"
                  @back="activeAssignment('homework')"
                  @showClassManagementSide="value => showClassManagementSide(value)"
                />
              </template>
            </template>

            <router-view v-else />
          </template>
          <!-- Class Management ends -->

          <!-- Students starts -->
          <template v-if="activeClassTab ==='student-management'">

            <b-row
              v-if="(topScorer && topScorer.totalPoints > 0) || (firstDoingStudent && firstDoingStudent.totalFirstLessonDone > 0)"
            >
              <b-col md="12">
                <b-card
                  class="mb-1"
                  body-class="p-1"
                >
                  <div>
                    <b-col
                      md="12"
                    >
                      <div class="d-none d-lg-block text-right">
                        <feather-icon
                          v-b-tooltip.hover
                          v-b-modal.badges-info
                          :title="$t('labels.badges-info')"
                          icon="InfoIcon"
                          size="22"
                          fill="#ffe699"
                          color="#777"
                          class="info-icon cursor-pointer"
                        />
                      </div>
                    </b-col>
                    <text-slider
                      class="d-flex"
                      style="width: 98%;"
                    >
                      <div class="d-flex">
                        <!-- highest point -->
                        <div
                          v-if="topScorer && topScorer.totalPoints > 0"
                          class="mr-2"
                        >
                          <span><b>{{ topScorer.fullname }}</b></span>
                          <span>
                            {{ $t("class-module.highest-points") }} :  {{ topScorer.totalPoints }}
                          </span>
                          <span>
                            <img
                              class="badge-icon"
                              :src="
                                require(`@/assets/images/${badges.highestPointGainerBadge}`)
                              "
                              style="width:30px; height:30px"
                            >
                          </span>
                        </div>
                        <!-- First Achiever -->
                        <div
                          v-if="firstDoingStudent && firstDoingStudent.totalFirstLessonDone > 0"
                          class="d-flex"
                        >
                          <span><b>{{ firstDoingStudent.fullname }}</b></span>
                          <span> {{ $t("class-module.lesson-completed") }} </span>
                          <img
                            class="badge-icon"
                            :src="
                              require(`@/assets/images/${badges.firstLessonAchieverBadge}`)
                            "
                            style="width:30px; height:30px"
                          >
                          <p class="mb-0 text-center">
                            X {{ firstDoingStudent.totalFirstLessonDone }}
                          </p>
                        </div>
                      </div>

                    </text-slider>

                  </div>
                </b-card>
              </b-col>
            </b-row>

            <template v-if="classManagementSide === 'pair-students'">
              <student-pairing
                :class-room-id="Number(classId)"
              />
            </template>
            <template v-else>
              <!-- students list -->
              <template
                v-if="filteredStudentsLists.length > 0"
              >
                <b-overlay
                  :show="isProcessing"
                >
                  <vue-perfect-scrollbar
                    ref="scrollContainer"
                    :settings="perfectScrollbarSettings"
                    class="scroll-content"
                    @scroll="handleStudentListScroll"
                  >
                    <b-row
                      class="w-100 students-detail-card"
                    >
                      <b-col
                        v-for="(student, index) in filteredStudentsLists"
                        :key="student.id"
                        md="4"
                        :xl="notResponsiveWindow ? 4 : 3"
                        lg="4"
                        class="pr-0"
                      >
                        <b-card class="student-details-card pt-0 mb-1">
                          <b-row class="badge-row">
                            <b-col
                              md="5"
                              cols="5"
                              class="px-0"
                            >
                              <div>
                                <h5
                                  v-if="!hidePoint && !hideStudentPoint"
                                  :class="
                                    ((isAStudent && student.id === self.id) ||
                                      !isAStudent) && !hideStudentPointDetail
                                      ? 'cursor-pointer'
                                      : ''
                                  "
                                  @click="(!hideStudentPointDetail) ? showRewardsModal(student.id) : null"
                                >
                                  <feather-icon
                                    :icon="pointIcon"
                                    fill="#FFC107"
                                    size="16"
                                  />
                                  {{ student.totalPoints ? student.totalPoints : "0" }}
                                  {{ $t("labels.points") }}
                                  <feather-icon v-b-tooltip.hover.top="$t('class-module.point-info')"
                                                icon="InfoIcon"
                                                class="text-success"
                                  />
                                </h5>
                              </div>
                            </b-col>
                            <div class="col-7 p-0 m-0 text-right">
                              <span
                                v-if="student.lang_level !== 'none' && student.lang_level && !hideLanguageLevel"
                                v-b-tooltip.hover="$t('labels.language-level')"
                              >
                                <span
                                  class="badge text-white"
                                  :style="{
                                    background: getColorForLevel(student.lang_level),
                                  }"
                                >
                                  {{ student.lang_level }}</span>
                              </span>
                            </div>
                          </b-row>
                          <b-row class="border-bottom pb-1 mb-1">
                            <b-col
                              class="col-3 px-md-0"
                              :class="!isAStudent ? 'cursor-pointer' : ''"
                              @click="
                                !isAStudent
                                  ? $router.push({
                                    name: `student-dashboard-for-${self.usertype}`,
                                    params: { id: student.id },
                                    query: { from: 'classroom' }
                                  })
                                  : ''
                              "
                              @mouseover="student.id === self.id ? showProfileEdit = true : false"
                              @mouseleave="showProfileEdit = false"
                            >
                              <div class="position-relative">
                                <label
                                  for="profilePicUpload"
                                  class="position-absolute cursor-pointer bottom-0 left-0"
                                  :style="{ zIndex: 1 }"
                                >
                                  <b-badge
                                    v-if="student.id === self.id && showProfileEdit"
                                    v-b-tooltip.hover="'Update Profile Picture'"
                                    variant="primary"
                                  >
                                    <feather-icon
                                      icon="Edit2Icon"
                                      size="20"
                                    />
                                  </b-badge>
                                </label>
                                <input
                                  id="profilePicUpload"
                                  type="file"
                                  hidden
                                  accept="image/jpeg, image/png, image/gif"
                                  @input="profileImageChanged"
                                >
                                <b-avatar
                                  v-if="student.avatar"
                                  badge
                                  square
                                  :badge-variant="
                                    student.isOnline ? 'success' : 'secondary'
                                  "
                                  class="student-avatar position-relative"
                                  badge-offset="-0.1em"
                                  :src="student.avatar"
                                />
                                <div
                                  v-else
                                  class="student-no-avatar"
                                >
                                  <avatar
                                    :username="student.fullname"
                                    :rounded="false"
                                    class="student-avatar"
                                  />
                                  <div
                                    :class="`avatar-status-indicator avatar-${
                                      student.isOnline ? 'online' : 'offline'
                                    }`"
                                  />
                                </div>
                              </div>
                              <div
                                v-if="roles.conductor === student.role"
                                v-b-tooltip.hover="
                                  $t('class-module.student-role.conductor')
                                "
                                class="ribbon"
                              >
                                <span>
                                  {{
                                    $t("class-module.student-role.conductor-half-name")
                                  }}
                                </span>
                              </div>
                            </b-col>
                            <b-col
                              class="p-sm-n-1 pb-0 col-7 px-md-0"
                            >
                              <h4
                                v-b-tooltip.hover
                                :class="!isAStudent ? 'cursor-pointer' : ''"
                                class="text-truncate"
                                :title="student.fullname"
                                @click="
                                  (!isAStudent && !isAParent) || isAParentChild(student.id)
                                    ? $router.push({
                                      name: `student-dashboard-for-${self.usertype}`,
                                      params: { id: student.id },
                                      query: { from: 'classroom' }
                                    })
                                    : ''
                                "
                              >
                                {{ student.fullname }}
                              </h4>
                              <div @click="canViewCouseProgress(student.id) && selectCourseDetails(student.id)">
                                <h5 class="cursor-pointer">
                                  <span class="float-right"
                                        style="margin-top: -4px; margin-left: 5px "
                                  >
                                    <feather-icon v-b-tooltip.hover.top="$t('class-module.progressbar-info')"
                                                  icon="InfoIcon"
                                                  class="text-success"
                                    />
                                  </span>
                                </h5>
                                <template v-if="singleDoubleCourseCompletionBar === 0">
                                  <b-progress
                                    :max="100"
                                  >
                                    <b-progress-bar v-if="+student.currentProgress != +student.totalProcess && hideCurrentProgress===0"
                                                    v-b-tooltip.hover.top="`${$t('current-progress')} ${student.currentProgress} `"
                                                    :value="student.currentProgress"
                                                    class="progress-bar-warning"
                                                    :class="canViewCouseProgress(student.id) ? 'clickable-div': ''"
                                                    variant="warning"
                                                    style="cursor: pointer"
                                    >
                                      <strong>{{ `${student.currentProgress} %` }} </strong>
                                    </b-progress-bar>
                                    <b-progress-bar
                                      v-if="!hideFullCourseProgress"
                                      v-b-tooltip.hover.top="`${$t('total-progress')} ${student.totalProcess}%`"
                                      :value="student.totalProcess"
                                      class="progress-bar-success"
                                      :class="canViewCouseProgress(student.id) ? 'clickable-div': ''"
                                      variant="success"
                                      style="cursor: pointer"
                                    >
                                      <strong>{{ `${student.totalProcess}%` }} </strong>
                                    </b-progress-bar>
                                  </b-progress>
                                  <p
                                    :style="{
                                      cursor: canViewCouseProgress(student.id) && 'pointer',
                                    }"
                                    class="pb-0 mb-0 text-muted italic"
                                    @click="selectCourseDetails(student.id)"
                                  >
                                    {{ $t("class-module.course-completion") }}
                                  </p>
                                </template>
                                <template v-else>
                                  <b-progress
                                    :max="100"
                                  >
                                    <b-progress-bar
                                      v-if="+student.currentProgress != +student.totalProcess && hideCurrentProgress===0"
                                      v-b-tooltip.hover.top="`${$t('current-progress')} ${student.currentProgress} `"
                                      :value="student.currentProgress"
                                      class="progress-bar-warning"
                                      :class="canViewCouseProgress(student.id) ? 'clickable-div': ''"
                                      variant="warning"
                                      style="cursor: pointer;"
                                    >
                                      <strong>{{ `${student.currentProgress} %` }} </strong>
                                    </b-progress-bar>

                                  </b-progress>
                                  <p
                                    :style="{
                                      cursor: canViewCouseProgress(student.id) && 'pointer',
                                    }"
                                    class="pb-0 mb-0 text-muted italic"
                                    @click="selectCourseDetails(student.id)"
                                  >
                                    {{ $t('current-progress') }}
                                  </p>
                                  <b-progress
                                    :max="100"
                                  >
                                    <b-progress-bar
                                      v-if="!hideFullCourseProgress"
                                      v-b-tooltip.hover.top="`${$t('total-progress')} ${student.totalProcess}%`"
                                      :value="student.totalProcess"
                                      class="progress-bar-success"
                                      :class="canViewCouseProgress(student.id) ? 'clickable-div': ''"
                                      variant="success"
                                      style="cursor: pointer;"
                                    >
                                      <strong>{{ `${student.totalProcess}%` }} </strong>
                                    </b-progress-bar>
                                  </b-progress>
                                  <p
                                    :style="{
                                      cursor: canViewCouseProgress(student.id) && 'pointer',
                                    }"
                                    class="pb-0 mb-0 text-muted italic"
                                    @click="selectCourseDetails(student.id)"
                                  >
                                    {{ $t('total-progress') }}
                                  </p>
                                </template>
                              </div>
                            </b-col>
                            <b-col class="col-2 px-0 text-right">
                              <template v-if="!hideBadge">
                                <div class="mb-1">
                                  <img
                                    v-if="index === 0 && student.totalPoints > 0"
                                    v-b-tooltip.hover="'Highest Points Gainer'"
                                    class="top-badge"
                                    :src="require(`@/assets/images/${badges.highestPointGainerBadge}`)"
                                  >
                                </div>
                                <!-- :style="`right:${topScorer && topScorer.length>0 && student.id==topScorer[0].user.id ? '35px' : '8px'}`" -->
                                <div class="mb-1">
                                  <small
                                    v-if="
                                      student.totalFirstLessonDone &&
                                        student.totalFirstLessonDone > 1
                                    "
                                  >{{ student.totalFirstLessonDone }}x
                                  </small>
                                  <img
                                    v-if="student.totalFirstLessonDone > 0"
                                    v-b-tooltip.hover="'First Lesson Acheiver'"
                                    class="first-badge ml-0"
                                    :src="
                                      require(`@/assets/images/${badges.firstLessonAchieverBadge}`)
                                    "
                                  >
                                </div>
                                <div>
                                  <img
                                    v-if="additionalResult[index]"
                                    v-b-tooltip.hover="'Extraordinary'"
                                    class="first-badge"
                                    :src="
                                      require(`@/assets/images/${badges.extraOrdinaryBadge}`)
                                    "
                                  >
                                </div>
                              </template>
                            </b-col>
                          </b-row>

                          <b-row>
                            <b-col class="mb-0">
                              <div class="d-flex justify-content-between">
                                <template v-if="student.hasPairs">
                                  <div v-if="isHighLevelUser || isATeacher">
                                    <h5
                                      role="button"
                                      @click="showMyPair = student.id"
                                    >
                                      <feather-icon icon="UsersIcon" /> Pairs
                                    </h5>
                                  </div>
                                  <div
                                    v-else-if="isAStudent && self.id == student.id"
                                    role="button"
                                    @click="showMyPair = student.id"
                                  >
                                    <h5><feather-icon icon="UsersIcon" /> My Pairs</h5>
                                  </div>
                                </template>
                              </div>
                            </b-col>
                          </b-row>
                          <b-row class="mb-1">
                            <b-col
                              md="10"
                              cols="10"
                              class="d-flex pl-0 student-report-btns"
                            >
                              <feather-icon
                                v-b-tooltip.hover
                                :title="$t('class-module.show-skill-chart-title')"
                                icon="BarChart2Icon"
                                size="35"
                                :class="{'disabled': !((isATeacher || isHighLevelUser || student.id == self.id || isAParentChild(student.id)) && hideSkillChart ===0 && hideStudentSkilChart === 0)}"
                                color="purple"
                                class="action-icon"
                                @click.stop="studentIdToShowSkill = student.id"
                              />
                              <feather-icon
                                v-if="hideStudentDiary === 0"
                                v-b-tooltip.hover
                                icon="ClockIcon"
                                size="35"
                                color="blue"
                                :class="{'disabled': !(isATeacher ||
                                  student.id == self.id ||
                                  isHighLevelUser ||
                                  isAParentChild(student.id))}"
                                :title="$t('student-report-module.view-student-diary')"
                                class="action-icon"
                                @click.stop="showAttendance(student)"
                              />
                              <feather-icon
                                v-b-tooltip.hover
                                icon="GridIcon"
                                size="35"
                                color="green"
                                :class="{'disabled': !(!hideStudentRubrics && (isATeacher ||
                                  student.id == self.id ||
                                  isHighLevelUser ||
                                  isAParentChild(student.id)))}"
                                :title="
                                  $t('student-report-module.view-student-rubrics')
                                "
                                class="action-icon"
                                @click.stop="showRubric(student)"
                              />
                              <feather-icon
                                v-b-tooltip.hover
                                icon="ClipboardIcon"
                                size="35"
                                color="orange"
                                :class="{'disabled': !(!hideStudentNotes && (isATeacher ||
                                  student.id == self.id ||
                                  isHighLevelUser ||
                                  isAParentChild(student.id)))}"
                                :title="$t('student-report-module.view-student-notes')"
                                class="action-icon"
                                @click.stop="studentIdToSeeNotes = student.id"
                              />
                              <feather-icon
                                v-b-tooltip.hover
                                icon="FileTextIcon"
                                size="35"
                                color="olive"
                                :class="{'disabled': !(!hideStudentIndividualReport && (isATeacher ||
                                  student.id == self.id ||
                                  isHighLevelUser ||
                                  isAParentChild(student.id)))}"
                                :title="$t('student-report-module.individual-report')"
                                class="action-icon"
                                @click.stop="openClassroomReport(student.id)"
                              />
                            </b-col>
                            <b-col
                              md="1"
                              cols="2"
                              class="pl-0"
                            >
                              <student-more-option
                                v-if="isATeacher || isHighLevelUser"
                                :student="student"
                                :class-id="classId"
                                :room="room"
                                :is-high-level-user="isATeacher || isHighLevelUser"
                                @refetchStudent="getClassRoomStat"
                                @deleteStudent="showDeleteModalForStudent(student.id)"
                                @moveStudent="showMoveStudentModal(student)"
                                @addLiveLink="studentToAddLink = student"
                              />
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                    </b-row>
                  </vue-perfect-scrollbar>
                </b-overlay>
              </template>
              <template v-else>
                <b-row>
                  <b-col>
                    <b-card class="student-details-card p-2">
                      {{ $t('no-student-added-in-class') }}
                    </b-card>
                  </b-col>
                </b-row>
              </template>
              <!-- students list -->
            </template>

          </template>
          <!-- Students ends -->

          <!-- Reports starts -->
          <template v-if="activeClassTab === 'report-management'">
            <template v-if="reportTypeArray.includes(classManagementSide)">
              <ClassReport
                :report-type="classManagementSide"
                :class-room-id="Number(classId)"
              />
            </template>
            <template v-if="classManagementSide === 'class-monitor'">
              <RealTimeMonitor
                :class-room-id="Number(classId)"
              />
            </template>
            <template v-if="classManagementSide === 'overall-report'">
              <HigherLevelReport
                :class-room-id="Number(classId)"
              />
            </template>
          </template>
          <!-- Reports ends -->
        </div>
      </b-col>
    </b-row>

    <student-rubric-modal
      :student="viewingRubricsOfStudent"
      :show="viewingRubricsOfStudent === null"
      :class-id="room ? room.id : null"
      :rubrics="activeClassRubrics"
      :is-teacher="isAClassTeacher"
      @close="viewingRubricsOfStudent = null"
    />
    <delete-modal
      name="modal-delete-student"
      :on-delete="removeStudentFromAClass"
      :title="$t('class-module.delete-student-title')"
      :sub-title="$t('class-module.delete-student-question')"
      :is-processing-delete="isProcessingDelete"
      :show="studentBeingDeleted != null"
      @close="studentBeingDeleted = null"
    />
    <add-students
      :students="students.data || []"
      :last_page="students.last_page || 1"
      :class-id="classId"
      @close="()=>{searchStudentInput=' '; getStudentsForClass()}"
      @fetchMoreStudents="fetchMoreStudents"
      @searchStudents="searchStudents"
      @onStudentsAdded="getClassRoomStat()"
    />
    <course-completion-details
      :toggle="courseDetailsToggle"
      :class-id="Number(classId)"
      :selected-student-id="selectedStudentId"
      @courseCompletionModalClosed="courseDetailsToggle = false"
    />
    <class-rubric-modal
      :show="showClassRubrics"
      :classe="room"
      :rubrics="activeClassRubrics"
      @close="showClassRubrics = false"
      @refreshRubrics="getClassRubrics"
    />

    <!-- Award Stat Group Modal -->
    <award-stats-group
      modal-id="classroom-rewards"
      item="points"
      :student-id="studentId"
      :class-id="parseInt(classId, 10)"
    />

    <!-- Badges Info Model -->
    <badges-info-modal
      v-if="!hideBadgesInfo"
      :badges-icons="badges"
    />
    <skill-preview
      v-if="!!studentIdToShowSkill"
      :show="true"
      :class-room="room"
      :student-id="Number(studentIdToShowSkill)"
      :student-info="
        studentWithStat.find((student) => student.id == studentIdToShowSkill)
      "
      :hide-language-summary-chart="hideLanguageSummaryChart"
      :hide-skill-summary-chart="hideSkillSummaryChart"
      :hide-student-role-chart="hideStudentRoleChart"
      :hide-student-note-chart="hideStudentNoteChart"
      :hide-student-game-report="hideStudentGameReport"
      @close="studentIdToShowSkill = null"
    />
    <my-pairs
      :show="!!showMyPair"
      :class-id="parseInt(classId)"
      :student-id="typeof showMyPair === 'number' ? showMyPair : 0"
      @close="showMyPair = false"
    />
    <my-pairs
      :show="!!studentIdToSeeNotes"
      :class-id="parseInt(classId)"
      :only-notes="true"
      :student-id="typeof studentIdToSeeNotes === 'number' ? studentIdToSeeNotes : 0"
      @close="studentIdToSeeNotes = false"
      @closeNote="studentIdToSeeNotes = false"
    />

    <!-- Move Student Modal -->
    <move-student-modal
      :student="studentBeingMoved"
      :current-room="currentRoom"
      @onStudentMoved="getClassRoomStat"
    />

    <reservation-schedule v-if="showSchedules"
                          :class-id="classId"
                          @close="showSchedules = false"
    />
    <b-modal
      :visible="!!redirectingLink"
      content-class="shadow"
      title="Opening"
      no-fade
      ok-only
      ok-title="Accept"
      hide-footer
      @hide="redirectingLink = null"
      @close="redirectingLink = null"
    >
      <b-overlay
        show
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        Redirecting to {{ redirectingLink }}
      </b-overlay>
    </b-modal>
    <b-modal v-if="!!studentToAddLink"
             :visible="true"
             title="Add Live Classroom Link For Individual Student"
             @hide="studentToAddLink = null;"
             @close="studentToAddLink = null;"
    >
      <b-form-group>
        <b-form-input
          v-model="studentToAddLink.live_link"
          placeholder="Paste Live Classroom Link Here"
        />
        <p class="text-muted pt-2"><i>
          When students need a different classroom, use this link to join a live session with the teacher, tailored to specific needs. It provides personalized support and direct interaction for effective learning.
        </i></p>
      </b-form-group>
      <template #modal-footer>
        <b-button variant="primary"
                  :disabled="!!isProcessing"
                  @click="updateStudentLiveLink"
        >
          Submit
        </b-button>
      </template>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BButton,
  BBadge,
  BCol,
  BRow,
  VBTooltip,
  BProgress,
  BProgressBar,
  BSpinner,
  BAvatar,
  // BLink,
  BOverlay,
  BTabs,
  BTab,
  BFormCheckbox,
  BModal,
  BFormGroup,
  BFormInput,
  BNav,
  BNavItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Avatar from 'vue-avatar'
import vSelect from 'vue-select'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VueApexCharts from 'vue-apexcharts'
import useJwt from '@/auth/jwt/useJwt'

import { getUserData, isHighLevelUser, isAParentChild } from '@/auth/utils'
import i18n from '@/libs/i18n'
import {
  USER_TYPE_SCHOOL,
  USER_TYPE_STUDENT,
  USER_TYPE_SUPER,
  USER_TYPE_TEACHER,
  USER_TYPE_PARENT,
} from '@/const/userType'
import DeleteModal from '@/views/common/components/DeleteModal.vue'
import AddStudents from '@/views/common/class/components/AddStudents.vue'
import StudentRubricModal from '@/views/common/class/components/rubric/components/StudentRubricModal.vue'
import useApollo from '@/@core/graphql/useApollo'
import { getLevelFromScore, getColorForLevel } from '@/const/langLevel'
import {
  HIGHEST_POINTS_GAINER_BADGE,
  FIRST_LESSON_ACHEIVER_BADGE,
  EXTRAORDINARY_BADGE,
  POINT_ICON,
} from '@/const/badges'
import fonts from '@/const/fonts'
import ClassRubricModal from '@/views/common/class/components/rubric/components/ClassRubricModal.vue'
import AwardStatsGroup from '@/views/common/dashboard/components/AwardStatsGroup.vue'
import { CONDUCTOR } from '@/const/studentRoles'
import { engagementSettings, isVisible } from '@/utils/visibilitySettings'
import TextSlider from '@/views/common/components/TextSlider.vue'
import ClassEssay from '@/views/v2/common/class/class-essay/index.vue'
import VocabLesson from '@/views/student/vocab-lesson/index.vue'
import StudentLesson from '@/views/student/lesson/Index.vue'
import Lesson from '@/views/student/lesson/Show.vue'
import Notification from '@/views/common/notifications/Index.vue'
import Calendar from '@/views/teacher/class/components/calendar/Calendar.vue'
import HigherLevelReport from '@/views/common/higher-level-report/index.vue'
import RealTimeMonitor from '@/views/v2/common/class/Monitor/index.vue'
import ClassReport from '@/views/common/class/Show.vue'
import StudentPairing from '@/views/v2/common/class/paring/index.vue'
import GEC_EVENT_BUS from '@/utils/eventBus'
import ClassTest from '@/views/student/lesson/ClassTest/index.vue'
import BadgesInfoModal from './BadgesInfoModal.vue'
import CourseCompletionDetails from './CourseCompletionDetails.vue'
import SkillPreview from './SkillPreview.vue'
import StudentMoreOption from './StudentMoreOptions.vue'
import Attendance from './Attendance.vue'
import MyPairs from './MyPairs.vue'
import MoveStudentModal from './MoveStudentModal.vue'

import StudentTableNavV2 from './StudentTableNavV2.vue'
import GameSettingsModal from './GameSettingsModal.vue'
import ClassScript from './class-script/index.vue'
import ReservationSchedule from './schedules/reservation-schedule.vue'
import ClassDetailHead from './ClassDetailHead.vue'

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    BCol,
    BRow,
    BProgress,
    BSpinner,
    // BLink,
    BOverlay,
    BModal,
    StudentRubricModal,
    Avatar,
    DeleteModal,
    AddStudents,
    vSelect,
    BAvatar,
    CourseCompletionDetails,
    ClassRubricModal,
    AwardStatsGroup,
    BadgesInfoModal,
    SkillPreview,
    StudentMoreOption,
    Attendance,
    MyPairs,
    MoveStudentModal,
    GameSettingsModal,
    ClassScript,
    BTabs,
    BTab,
    BProgressBar,
    BFormCheckbox,
    ReservationSchedule,
    BFormGroup,
    BFormInput,
    VuePerfectScrollbar,
    StudentTableNavV2,
    TextSlider,
    ClassEssay,
    ClassDetailHead,
    VocabLesson,
    StudentLesson,
    Lesson,
    VueApexCharts,
    Notification,
    Calendar,
    HigherLevelReport,
    RealTimeMonitor,
    ClassReport,
    StudentPairing,
    BNav,
    BNavItem,
    ClassTest,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },

  provide() {
    return {
      injectedClassRoomId: +this.classId,
    }
  },
  props: {
    search: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      columnsForStudentsTable: [
        { key: 'fullname', label: i18n.tc('class-module.student-table.name') },
        {
          key: 'action',
          label: i18n.tc('class-module.student-table.actions'),
          thStyle: { minWidth: '100px' },
        },
      ],
      self: getUserData(),
      activeClass: [],
      students: { data: [] },
      isProcessing: false,
      isProcessingDelete: false,
      viewingRubricsOfStudent: null,
      activeClassRubrics: [],
      studentBeingDeleted: null,
      searchInput: null,
      filterStudent: null,
      fetchingTimeout: null,
      room: {},
      searchUser: [],
      lessonCompletor: null,
      additionalResult: {},
      badges: {
        highestPointGainerBadge: HIGHEST_POINTS_GAINER_BADGE,
        firstLessonAchieverBadge: FIRST_LESSON_ACHEIVER_BADGE,
        extraOrdinaryBadge: EXTRAORDINARY_BADGE,
      },
      pointIcon: POINT_ICON,
      cefrRate: {},
      courseDetailsToggle: false,
      selectedStudentId: null,
      isProcessingIndividual: false,
      courses: [],
      showClassRubrics: false,
      studentWithStat: [],
      studentId: null,
      studentIdToShowSkill: null,
      roles: {
        conductor: CONDUCTOR,
      },
      showMyPair: false,
      studentBeingMoved: null,
      filteredStudentsLists: [],
      showStudentScoreBoard: false,
      showGroupReportModal: false,
      requestProcessing: false,
      studentIdToSeeNotes: null,
      courseByProgress: [],
      todayClassTests: [],
      isAParentChild,
      showTestModal: false,
      showClassScript: false,
      page: 1,
      searchStudentInput: '',
      selfStudyClassRoom: 0,
      overAllCourseCompletion: 0.0,
      showSchedules: false,
      isUpdatingToPaid: false,
      redirectingLink: null,
      studentToAddLink: null,
      followUpCourses: [],
      activeTab: 0,
      hideFullCourseProgress: 0,
      hideCurrentProgress: 0,
      hideSkillChart: 0,
      hidePoint: 0,
      hideLanguageLevel: 0,
      hideBadge: 0,
      hideBadgesInfo: 0,
      hideStudentSkilChart: 0,
      hideStudentDiary: 0,
      hideStudentRubrics: 0,
      hideStudentNotes: 0,
      hideStudentIndividualReport: 0,
      singleDoubleCourseCompletionBar: 0,
      defaultIndividualReport: null,
      hideStudentCards: 0,
      hideStudentPoint: 0,
      hideStudentPointDetail: 0,
      hideTeacher: 0,
      hideCoTeacher: 0,
      hideSchool: 0,
      hideOwner: 0,
      hideClassroomListButton: 0,
      hideClassTest: 0,
      hideViewEssay: 0,
      hideShowScript: 0,

      hideLanguageSummaryChart: 0,
      hideSkillSummaryChart: 0,
      hideStudentRoleChart: 0,
      hideStudentNoteChart: 0,
      hideStudentGameReport: 0,
      hasVocabLesson: false,
      isFetchingStudentStats: false,
      studentCurrentPage: 1,
      studentLimit: 30,
      totalStudents: 0,
      studentLastPage: null,
      uniquePair: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
        swipeEasing: true,
      },
      showClassroomDetailModal: false,
      activeClassTab: 'class-management',
      activeAssignmentTab: 'homework',
      viewLessonGroup: false,
      selectedCourseId: null,
      selectedCourseType: null,
      viewLesson: false,
      selectedLessonId: null,
      selectedEventId: null,
      selectedMode: null,
      classManagementSide: 'assignment',
      reportTypeArray: ['course-report', 'academic-skills', 'language-skills', 'study-skills', 'social-skills', 'game-report', 'class-test-report'],
      showFullSidebar: this.isNarrowDevice ? true : (localStorage.getItem('showFullSidebar') && localStorage.getItem('showFullSidebar') === 'true') ?? true,
      showProfileEdit: false,
      showMobileSidebar: false,
      isRouterView: this.$route.meta.isRouterView,
    }
  },
  computed: {
    ...mapGetters('appConfig', ['getSchoolSettings', 'getDistrictSettings']),
    topScorer() {
      return this.studentWithStat[0] ?? null
    },
    isNarrowDevice() {
      return this.$store.getters['app/isNarrowMode']
    },
    isTrailUser() {
      return this.self.user_subscription_type === 'external_trial'
    },
    isFullAccessUser() {
      return this.self.user_subscription_type === 'external_full_access'
    },
    isFullAccessWithTutorUser() {
      return this.self.user_subscription_type === 'external_full_access_tutor' || !this.self.user_subscription_type
    },
    canRequestTeacher() {
      return this.isFullAccessWithTutorUser && ((!this.room.teacher || this.room.teacher.lastname.includes('default')) && this.room.reservation_id)
    },
    isATeacher() {
      return this.self.usertype === USER_TYPE_TEACHER
    },
    isAClassTeacher() {
      const teachers = this.room?.teachers?.map(teacher => Number(teacher.id)) || []
      return this.room && this.room.teachers
        ? teachers.includes(Number(this.self.id))
        : false
    },
    isAStudent() {
      return this.self.usertype === USER_TYPE_STUDENT
    },
    isAnAdmin() {
      return this.self.usertype === USER_TYPE_SUPER
    },
    isASchool() {
      return this.self.usertype === USER_TYPE_SCHOOL
    },
    isAParent() {
      return this.self.usertype === USER_TYPE_PARENT
    },
    classId() {
      // Return the class ID from route params, falling back to id param if classId not present
      return this.$route.params.classId || this.$route.params.id
    },
    isAClassConductor() {
      const conductor = this.studentWithStat.find(stat => stat.conductor === 1 || stat.role === this.roles.conductor)
      return conductor && Number(this.self.id) === conductor.id
    },
    notResponsiveWindow() {
      return window.innerWidth >= 1280 && window.innerWidth < 1600
    },
    getStudentsByPointOrder() {
      const rooms = this.room.students
      if (!rooms) return []
      // rooms.sort((a, b) => b.loaders[0]?.points - a.loaders[0]?.points)
      rooms.sort((a, b) => {
        const previousOrder = a.loaders.reduce((p, n) => p + n.points, 0)
        const currentOrder = b.loaders.reduce((p, n) => p + n.points, 0)
        return currentOrder - previousOrder
      })
      return rooms
    },
    isHighLevelUser() {
      return (
        isHighLevelUser()
        || Number(this.room?.owner?.id) === Number(this.self.id)
      )
    },
    firstDoingStudent() {
      const studentStat = [...this.studentWithStat]
      studentStat.sort(
        (a, b) => b.totalFirstLessonDone - a.totalFirstLessonDone,
      )
      return studentStat[0] ?? null
    },
    currentRoom() {
      return {
        id: this.room.id,
        name: this.room.name,
      }
    },
    courseProgress() {
      const value = this.overAllCourseCompletion
      if (value >= 25.0 && value <= 50.0) { return 'warning' }
      if (value > 50 && value <= 75.0) {
        return 'primary'
      }
      if (value > 75.0) {
        return 'success'
      }
      return 'danger'
    },
    noOfStudentSettings() {
      return {
        distNoOfStudentInfo: this.getDistrictSettings?.find(metaValue => metaValue.key === 'show_no_of_students')?.value ?? '0',
        schoolNoOfStudentInfo: this.getSchoolSettings?.find(metaValue => metaValue.key === 'show_no_of_students')?.value ?? '0',
      }
    },
    hideNoOfStudent() {
      return this.isAStudent && (this.noOfStudentSettings?.distNoOfStudentInfo === '1' || this.noOfStudentSettings?.schoolNoOfStudentInfo === '1')
    },
    inProgressCompletedTabVisbile() {
      const schoolSettings = this.$store.state.appConfig?.schoolInfo?.user_meta
      const districtSettings = this.$store.state.appConfig?.districtInfo?.user_meta

      return isVisible('in_progress_completed', schoolSettings, districtSettings)
    },
  },
  watch: {
    showClassRubrics(value) {
      if (value && !this.activeClassRubrics) {
        this.getClassRubrics()
      }
    },
    filterStudent() {
      this.getStudentsListOfClassroom()
    },
  },
  mounted() {
    this.$store.commit('appConfig/UPDATE_SHOW_AVATAR', false)
    this.getStudentsOfClassRooms()
    this.searchStudent('')
    this.getClassRoomStat()
    if (this.isAStudent) {
      this.checkAndGetClassTests()
    }
    this.getClassRoomSettings()
    if (this.$route.query['view-lesson']) {
      this.viewLessons(this.$route.query['view-lesson'], this.classId)
    }
    if (this.$route.query['view-tab']) {
      this.showClassManagementSide(this.$route.query['view-tab'])
    }
  },
  methods: {
    studentDropdownFilter(option, search) {
      return option.filter(lb => lb.fullname.toLowerCase().includes(search.toLowerCase()) || lb.email.toLowerCase().includes(search.toLowerCase()))
    },
    updateStudentLiveLink() {
      this.isProcessing = true
      useJwt.updateStudentLiveLink(this.classId, this.studentToAddLink.id, {
        live_link: this.studentToAddLink.live_link,
      }).then(response => {
        this.showSuccessMessage(response)
        this.getClassRoomStat()
        this.studentToAddLink = null
      }).finally(() => {
        this.isProcessing = false
      })
    },
    formatClassName(name) {
      return name?.split('#')[0]
    },
    openSSOLink(link) {
      const ssoProvider = link.split(process.env.VUE_APP_SSO_PREFIX)[1]
      useJwt.getSsoLink(ssoProvider).then(response => {
        window.open(response.data.data, '_blank')
        this.redirectingLink = null
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    openClassLink() {
      const { link } = this.room
      const { classId } = this
      this.redirectingLink = link
      useJwt.updateStudentAttendanceWithLiveLink({
        classroom_id: classId,
      }).then(response => {
        this.showSuccessMessage(response)
        if (link.startsWith(process.env.VUE_APP_SSO_PREFIX)) {
          this.openSSOLink(link)
        } else {
          window.open(link, '_blank')
          this.redirectingLink = null
        }
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    openClassroomReport(studentId) {
      this.$router.push({ name: `${this.AUTH_USER().usertype}-individual-report`, params: { studentId, defaultReport: this.defaultIndividualReport, schoolId: this.room.school.id }, query: { classroomId: this.classId } })
    },
    checkAndGetClassTests() {
      useJwt.checkAndGetClassTests(this.classId).then(response => {
        this.todayClassTests = response.data.data.class_tests
        this.showTestModal = !!this.todayClassTests.length
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    getClassRoomCoursesCount() {
      useJwt
        .getClassRoomCourseCount(this.classId)
        .then(response => {
          const { data } = response.data
          this.courses = data.courses
          const lessonCompleted = data.loaders_completed
          const courseByProgress = {
            pending: [],
            completed: [],
          }
          this.courses.forEach(course => {
            if (course.lessons?.every(lesson => lessonCompleted.includes(lesson))) {
              courseByProgress.completed.push(course)
            } else {
              courseByProgress.pending.push(course)
            }
          })
          this.courseByProgress = [
            {
              title: 'Courses',
              data: courseByProgress.pending,
            },
            {
              title: 'Completed',
              data: courseByProgress.completed,
            },
          ]
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
    },
    canViewCouseProgress(id) {
      return !this.isAStudent || this.self.id === Number(id)
    },
    selectCourseDetails(studentId) {
      if (!this.canViewCouseProgress(studentId)) {
        return
      }
      this.courseDetailsToggle = true
      this.isProcessingIndividual = true
      this.selectedStudentId = studentId
    },
    getRandomVariant() {
      const a = ['primary', 'secondary', 'success', 'danger', 'warning', 'info']
      return a[Math.floor(a.length * Math.random())]
    },
    getFilteredStudent() {
      this.isProcessing = true
    },
    getStudentClassCefr() {
      const { students } = this.room
      if (!students) return
      students.forEach((student, index) => {
        let cefr = 0
        const { loaders } = student
        loaders.forEach(loader => {
          cefr += parseInt(loader.cefr_rate, 10)
        })
        this.cefrRate[index] = Math.round(cefr / loaders.length)
      })
    },

    showRubric(student) {
      this.viewingRubricsOfStudent = student
    },
    getClassRubrics() {
      this.isProcessing = true
      useJwt
        .getClassRubrics(this.classId)
        .then(res => {
          this.activeClassRubrics = res.data.data
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    showDeleteModalForStudent(id) {
      this.studentBeingDeleted = id
    },
    removeStudentFromAClass(bvModalEvt) {
      this.isProcessingDelete = true
      bvModalEvt.preventDefault()

      useJwt
        .removeStudentFromClass(this.classId, this.studentBeingDeleted)
        .then(response => {
          this.showSuccessMessage(response)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => {
          this.studentBeingDeleted = null
          this.getClassRoomStat()
          this.getStudentsOfClassRooms()
          this.isProcessingDelete = false
        })
    },
    showClassStats() {
      if (this.$store.state.appConfig.layout.ui === 'modern') {
        this.$router.push({
          name: `${this.self.usertype}-class`,
          params: {
            id: this.classId,
            courseId: 'course',
            reporting: 'reporting',
          },
        })
        return
      }
      if (this.room.type !== 'custom') {
        this.$router.push({
          name: `${this.self.usertype}-class`,
          params: { id: this.classId },
          query:
            this.self.id === this.viewingClassesOfId
              ? {}
              : { user_id: this.viewingClassesOfId },
        })
      } else {
        this.$router.push({
          name: `${this.self.usertype}-course`,
          params: { id: this.classId },
        })
      }
    },
    showNotification() {
      this.$router.push({
        name: 'send-notifications',
        query: {
          classId: this.classId,
        },
      })
    },
    viewLessons(id, classId, type) {
      this.isRouterView = true
      this.$router.push({
        name: `${this.self.usertype}-lessons`,
        params: { id, classId },
        query: { type, group: Number(this.$route.params.courseId) === Number(id) ? this.$route.query.group : null },
      })
    },
    openTestLesson(lesson, eventId) {
      this.isRouterView = true
      this.$router.push({
        name: `${this.self.usertype}-lesson`,
        params: { id: lesson.id, classId: this.classId },
        query: { event_id: eventId },
      })
    },
    searchStudent(value) {
      this.searchInput = value
      if (this.fetchingTimeout) clearTimeout(this.fetchingTimeout)
      this.fetchingTimeout = setTimeout(() => {
        useApollo
          .searchStudents(this.searchInput, parseInt(this.classId, 10))
          .then(res => {
            this.searchUser = this.ARRAY_UNIQUE_BY_KEY(res.data.searchUser, 'id')
          })
          .catch(error => {
            this.showErrorMessage(error)
          })
      }, 500)
    },
    getStudentsOfClassRooms() {
      this.getStudentsForClass()
      this.getStudentClassCefr()
    },
    showAttendance(student) {
      const name = this.isAStudent
        ? 'student-diary'
        : `${this.self.usertype}-view-attendance`
      this.$router.push({ name, params: { id: student.id }, query: { classId: this.classId } })
    },
    viewClassCalendar(id) {
      this.$router.push({
        name: `${this.self.usertype}-class-calendar`,
        params: { id, classId: id },
      })
    },
    getStudentsForClass() {
      useJwt.getStudentsForClass(this.classId, { page: this.page, limit: 60, search: this.searchStudentInput }).then(response => {
        if (this.page === 1) this.students = response.data.data || []
        else {
          const data = response.data.data.data ? response.data.data.data : []
          this.students.data = [...this.students.data, ...data]
        }
      })
    },
    getColorForLevel(lvl) {
      return getColorForLevel(lvl) || '#c72f43'
    },
    getLevelFromIndex(lvl) {
      return getLevelFromScore(lvl)
    },
    getPoints(index) {
      let points = 0
      if (this.room.students) {
        this.room.students[index].loaders.forEach(loader => {
          points += loader?.points
        })
      }

      return points
    },
    setClassRoomSetting(settings) {
      this.$store.dispatch('session/setClassSettings', { settings, selfStudyClassRoom: this.selfStudyClassRoom })
    },
    getClassRoomStat() {
      this.isFetchingStudentStats = true
      useJwt
        .getClassRoomStat(this.classId, {
          params: {
            studentId: this.filterStudent,
          },
        })
        .then(response => {
          const {
            // students,
            pairs, courses, classroom, settings, isSelfStudyMode, overAllCourseCompletion, followUpCourses, hasVocabLesson,
          } = response.data.data
          // let uniquePair = []
          pairs.forEach(pair => {
            const array = JSON.parse(pair)
            this.uniquePair = [...new Set([...this.uniquePair, ...array])]
          })

          this.selfStudyClassRoom = isSelfStudyMode ? 1 : 0
          this.courseByProgress = [
            {
              title: i18n.tc('all'),
              data: courses,
            },
            ...(this.inProgressCompletedTabVisbile ? [
              ...(courses.filter(item => item.lesson_completion < 100).length > 0 ? [{
                title: i18n.tc('courses-module.in_progress'),
                data: courses.filter(item => item.lesson_completion < 100),
              }] : []),
              ...(courses.filter(item => item.lesson_completion >= 100).length > 0 ? [{
                title: i18n.tc('courses-module.completed'),
                data: courses.filter(item => item.lesson_completion >= 100),
              }] : []),
            ] : []),
          ]
          // const privateLink = requiredFormat.find(i => +i.id === +this.self.id)?.live_link
          // classroom.link = privateLink || classroom.link
          this.room = classroom
          this.courses = courses
          const schoolData = {
            id: this.room.school.id,
            firstname: this.room.school.firstname,
            lastname: this.room.school.lastname,
            // font: this.room.school.meta[0].value,
          }
          const metas = this.room?.school?.meta ?? []
          metas.map(meta => {
            schoolData[meta.key] = meta.value
            return schoolData
          }, {})
          const settingValue = settings.find(option => option.setting_name === 'font')

          if (settingValue) {
            const selectedFont = fonts.find(option => option.value === settingValue.value)
            schoolData.font = selectedFont.text
          }

          localStorage.setItem('schoolData', JSON.stringify(schoolData))

          this.overAllCourseCompletion = parseFloat(overAllCourseCompletion)
          this.followUpCourses = followUpCourses
          this.hasVocabLesson = hasVocabLesson
        }).finally(() => {
          this.isFetchingStudentStats = false
          this.getStudentsListOfClassroom()
        })
    },
    handleStudentListScroll: _.debounce(function (e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target
      if (scrollTop < 3) {
        this.studentCurrentPage = 1
      } else if ((scrollHeight - (scrollTop + offsetHeight) < 5) && (this.studentLastPage >= this.studentCurrentPage)) {
        this.studentCurrentPage += 1
      }
      if (this.studentCurrentPage > this.studentLastPage) {
        this.studentCurrentPage = this.studentLastPage
      }
      if (!this.filterStudent) { this.getStudentsListOfClassroom() }
    }, 800),
    getStudentsListOfClassroom() {
      this.isProcessing = true
      const params = {
        page: this.studentCurrentPage,
        limit: this.studentLimit,
        studentId: this.filterStudent ? [this.filterStudent] : null,
      }
      useJwt.getStudentsListOfClassroom(this.classId, { params })
        .then(res => {
          this.studentLastPage = res.data.data.last_page
          this.totalStudents = res.data.data.total
          const students = res.data.data.data
          const requiredFormat = students.map(student => ({
            avatar: student.avatar,
            fullname: `${student.firstname} ${student.lastname}`,
            id: student.student_id,
            isOnline: student.isOnline,
            totalPoints: student.total_points,
            role: student.role,
            conductor: student.conductor,
            read: student.read,
            answer: student.answer,
            totalFirstLessonDone: student.total_first_lesson_done,
            hasPairs: this.uniquePair.includes(Number(student.student_id)),
            lang_level: student.lang_level,
            live_link: student.live_link,
            totalProcess:
              Number(student.lesson_completed) > 100
                ? 100
                : Number(student.lesson_completed).toFixed(2),
            currentProgress:
              Number(student.lesson_completion_upto_today) > 100
                ? 100
                : Number(student.lesson_completion_upto_today).toFixed(2),
            extraProgress:
              Number(student.lesson_completed) > 100
                ? Number(student.lesson_completed) - 100
                : null,
          }))
          requiredFormat.sort((a, b) => b.totalPoints - a.totalPoints)
          this.studentWithStat = requiredFormat
          this.filteredStudentsLists = requiredFormat
          const privateLink = requiredFormat.find(i => +i.id === +this.self.id)?.live_link
          this.room.link = privateLink || this.room.link
          this.isProcessing = false
        })
    },
    showRewardsModal(id) {
      if (this.isAStudent && id !== this.self.id) return
      this.studentId = id
      this.$nextTick(() => { this.$bvModal.show('classroom-rewards') })
    },
    showMoveStudentModal(student) {
      this.studentBeingMoved = student
      this.$nextTick(() => { this.$bvModal.show('move-student') })
    },
    updateToPaid(type) {
      this.isUpdatingToPaid = true
      useJwt.reservationPaidUpgrade(this.room.reservation_id, {
        type,
      }).then(response => {
        if (response.data.data.payment?.checkoutLink) {
          window.open(response.data.data.payment?.checkoutLink, '_self')
        }
        this.showSuccessMessage(response)
      }).finally(() => {
        this.isUpdatingToPaid = false
      })
    },
    requestTeacher() {
      if (this.room.reservation_id) {
        this.showSchedules = true
      } else {
        this.requestProcessing = true
        useJwt.requestTeacher({ classId: this.classId }).then(response => {
          this.showSuccessMessage(response)
        }).finally(() => { this.requestProcessing = false }).catch(error => {
          this.showErrorMessage(error)
        })
      }
    },
    applyAfterCssForCourseProgress(course) {
      const style = `<style>#course-btn-${course.course_id}:after{
          width: ${course.lesson_completion > 100 ? course.lesson_completion : course.lesson_completion}%;
        }</style>`
      return style
    },
    fetchMoreStudents(page) {
      this.page = page
      this.getStudentsForClass()
    },
    searchStudents(input) {
      this.searchStudentInput = input
      if (input) {
        this.page = 1
      }
      this.getStudentsForClass()
    },

    gotoLessonPlanner() {
      this.$router.push({
        name: 'teacher-lesson-planner',
        params: { classId: this.classId },
      })
    },

    updateSelfStudyClassRoom() {
      useJwt.updateClassroomSelfStudy(this.classId, { enable: this.selfStudyClassRoom }).then(res => {
        this.showSuccessMessage(res)
        this.getClassRoomStat()
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
    getClassRoomSettings() {
      useJwt.getClassRoomSettings(this.classId).then(res => {
        const generalSettings = res.data?.data
        const districtSettings = res.data?.district_settings
        this.hideFullCourseProgress = generalSettings.find(item => item.setting_name === 'full_course_progress')?.value || 0
        this.hideCurrentProgress = generalSettings.find(item => item.setting_name === 'current_progress')?.value || 0
        this.hideSkillChart = generalSettings.find(item => item.setting_name === 'skill_chart')?.value || 0
        this.hidePoint = generalSettings.find(item => item.setting_name === 'point')?.value || 0
        this.hideLanguageLevel = generalSettings.find(item => item.setting_name === 'language_level')?.value || 0
        this.hideBadge = generalSettings.find(item => item.setting_name === 'badge')?.value || 0
        this.singleDoubleCourseCompletionBar = +generalSettings.find(item => item.setting_name === 'single_double_course_completion')?.value
        if (!this.singleDoubleCourseCompletionBar) this.singleDoubleCourseCompletionBar = +res.data.district_settings.find(item => item.setting_name === 'single_double_course_completion')?.value || 0
        this.defaultIndividualReport = generalSettings.find(item => item.setting_name === 'default_individual_report')?.value
        if (!this.defaultIndividualReport) this.defaultIndividualReport = +res.data.district_settings.find(item => item.setting_name === 'default_individual_report')?.value || 0

        // check for district and school level settings
        // student skill chart
        const schoolStudentSkillChart = +generalSettings.find(item => item.setting_name === 'student_skill_chart')?.value || 0
        const districtStudentSkillChart = +districtSettings.find(item => item.setting_name === 'student_skill_chart')?.value || 0
        this.hideStudentSkilChart = districtStudentSkillChart === 1 || schoolStudentSkillChart === 1 ? 1 : 0

        // student diary
        const schoolStudentDiary = +generalSettings.find(item => item.setting_name === 'student_diary')?.value || 0
        const districtStudentDiary = +districtSettings.find(item => item.setting_name === 'student_diary')?.value || 0
        this.hideStudentDiary = schoolStudentDiary === 1 || districtStudentDiary === 1 ? 1 : 0

        // student rubrics
        const schoolStudentRubrics = +generalSettings.find(item => item.setting_name === 'student_rubrics')?.value || 0
        const districtStudentRubrics = +districtSettings.find(item => item.setting_name === 'student_rubrics')?.value || 0
        this.hideStudentRubrics = schoolStudentRubrics === 1 || districtStudentRubrics === 1 ? 1 : 0

        // student notes
        const schoolStudentNotes = +generalSettings.find(item => item.setting_name === 'student_notes')?.value || 0
        const districtStudentNotes = +districtSettings.find(item => item.setting_name === 'student_notes')?.value || 0
        this.hideStudentNotes = schoolStudentNotes === 1 || districtStudentNotes === 1 ? 1 : 0

        // student_individual_report
        const schoolStudentIndividualReport = +generalSettings.find(item => item.setting_name === 'student_individual_report')?.value || 0
        const districtStudentIndividualReport = +districtSettings.find(item => item.setting_name === 'student_individual_report')?.value || 0
        this.hideStudentIndividualReport = schoolStudentIndividualReport === 1 || districtStudentIndividualReport === 1 ? 1 : 0

        // streak persistence
        const schoolStreakPersistence = +generalSettings.find(item => item.setting_name === 'streak_persistence')?.value || 0
        const districtStreakPersistence = +districtSettings.find(item => item.setting_name === 'streak_persistence')?.value || 0
        this.hideBadgesInfo = schoolStreakPersistence === 1 || districtStreakPersistence === 1 ? 1 : 0

        // student cards
        const schoolStudentCard = +generalSettings.find(item => item.setting_name === 'student_card')?.value || 0
        const districtStudentCard = +districtSettings.find(item => item.setting_name === 'student_card')?.value || 0
        this.hideStudentCards = (this.isAStudent && (schoolStudentCard === 1 || districtStudentCard === 1)) ? 1 : 0

        // student point
        const schoolStudentPoints = +generalSettings.find(item => item.setting_name === 'student_point')?.value || 0
        const districtStudentPoints = +districtSettings.find(item => item.setting_name === 'student_point')?.value || 0
        this.hideStudentPoint = (schoolStudentPoints === 1 || districtStudentPoints === 1) ? 1 : 0

        // student point detail
        const schoolStudentPointDetail = +generalSettings.find(item => item.setting_name === 'student_point_popup')?.value || 0
        const districtStudentPointDetail = +districtSettings.find(item => item.setting_name === 'student_point_popup')?.value || 0
        this.hideStudentPointDetail = (schoolStudentPointDetail === 1 || districtStudentPointDetail === 1) ? 1 : 0

        // teacher
        const schoolTeacherSetting = +generalSettings.find(item => item.setting_name === 'teacher')?.value || 0
        const districtTeacherSetting = +districtSettings.find(item => item.setting_name === 'teacher')?.value || 0
        this.hideTeacher = (schoolTeacherSetting === 1 || districtTeacherSetting === 1) ? 1 : 0

        // co-teacher
        const schoolCoTeacherSetting = +generalSettings.find(item => item.setting_name === 'co_teachers')?.value || 0
        const districtCoTeacherSetting = +districtSettings.find(item => item.setting_name === 'co_teachers')?.value || 0
        this.hideCoTeacher = (schoolCoTeacherSetting === 1 || districtCoTeacherSetting === 1) ? 1 : 0

        // owner
        const schoolOwnerSetting = +generalSettings.find(item => item.setting_name === 'owner')?.value || 0
        const districtOwnerSetting = +districtSettings.find(item => item.setting_name === 'owner')?.value || 0
        this.hideOwner = (schoolOwnerSetting === 1 || districtOwnerSetting === 1) ? 1 : 0

        // school
        const schoolSchoolNameSetting = +generalSettings.find(item => item.setting_name === 'school')?.value || 0
        const districtSchoolNameSetting = +districtSettings.find(item => item.setting_name === 'school')?.value || 0
        this.hideSchool = (schoolSchoolNameSetting === 1 || districtSchoolNameSetting === 1) ? 1 : 0

        // classroom list button
        const schoolClassroomListSetting = +generalSettings.find(item => item.setting_name === 'classroom_list')?.value || 0
        const districtClassroomListSetting = +districtSettings.find(item => item.setting_name === 'classroom_list')?.value || 0
        this.hideClassroomListButton = (schoolClassroomListSetting === 1 || districtClassroomListSetting === 1) ? 1 : 0

        // Class Test
        const schoolClassTestSetting = +generalSettings.find(item => item.setting_name === 'class_test')?.value || 0
        const districtClassTestSetting = +districtSettings.find(item => item.setting_name === 'class_test')?.value || 0
        this.hideClassTest = (schoolClassTestSetting === 1 || districtClassTestSetting === 1) ? 1 : 0

        // view essay
        const schoolViewEssaySetting = +generalSettings.find(item => item.setting_name === 'view_essay')?.value || 0
        const districtViewEssaySetting = +districtSettings.find(item => item.setting_name === 'view_essay')?.value || 0
        this.hideViewEssay = (schoolViewEssaySetting === 1 || districtViewEssaySetting === 1) ? 1 : 0

        // show script
        const schoolShowScriptSetting = +generalSettings.find(item => item.setting_name === 'show_script')?.value || 0
        const districtShowScriptSetting = +districtSettings.find(item => item.setting_name === 'show_script')?.value || 0
        this.hideShowScript = (schoolShowScriptSetting === 1 || districtShowScriptSetting === 1) ? 1 : 0

        // language_summary_chart
        const schoolLanguageSummarySetting = +generalSettings.find(item => item.setting_name === 'language_summary_chart')?.value || 0
        const districtLanguageSummarySetting = +districtSettings.find(item => item.setting_name === 'language_summary_chart')?.value || 0
        this.hideLanguageSummaryChart = (schoolLanguageSummarySetting === 1 || districtLanguageSummarySetting === 1) ? 1 : 0

        // skill_summary_chart
        const schoolSkillSummarySetting = +generalSettings.find(item => item.setting_name === 'skill_summary_chart')?.value || 0
        const districtSkillSummarySetting = +districtSettings.find(item => item.setting_name === 'skill_summary_chart')?.value || 0
        this.hideSkillSummaryChart = (schoolSkillSummarySetting === 1 || districtSkillSummarySetting === 1) ? 1 : 0

        // skill_summary_chart
        const schoolStudentRoleSetting = +generalSettings.find(item => item.setting_name === 'student_role_chart')?.value || 0
        const districtStudentRoleSetting = +districtSettings.find(item => item.setting_name === 'student_role_chart')?.value || 0
        this.hideStudentRoleChart = (schoolStudentRoleSetting === 1 || districtStudentRoleSetting === 1) ? 1 : 0

        // student_note_chart
        const schoolStudentNoteSetting = +generalSettings.find(item => item.setting_name === 'student_note_chart')?.value || 0
        const districtStudentNoteSetting = +districtSettings.find(item => item.setting_name === 'student_note_chart')?.value || 0
        this.hideStudentNoteChart = (schoolStudentNoteSetting === 1 || districtStudentNoteSetting === 1) ? 1 : 0

        // student_game_report
        const schoolStudentGameSetting = +generalSettings.find(item => item.setting_name === 'student_game_report')?.value || 0
        const districtStudentGameSetting = +districtSettings.find(item => item.setting_name === 'student_game_report')?.value || 0
        this.hideStudentGameReport = (schoolStudentGameSetting === 1 || districtStudentGameSetting === 1) ? 1 : 0

        let settings = engagementSettings(['game', 'attention'], generalSettings, districtSettings)

        let avatarSetting = districtSettings.find(item => item.setting_name === 'avatar')
        if (Number(avatarSetting?.value)) {
          avatarSetting = generalSettings.find(item => item.setting_name === 'avatar')
        }

        if (avatarSetting) {
          settings = [
            ...settings,
            avatarSetting,
          ]
        }

        this.$store.dispatch('session/setClassSettings', { settings, selfStudyClassRoom: false })
      })
    },
    showNav(className) {
      const filterList = document.querySelector(className)
      if (filterList) {
        filterList.classList.toggle('mb-hide')
      }
    },
    showClassTab(tabName) {
      this.activeClassTab = tabName
      this.showClassManagementSide('assignment')
      if (this.activeClassTab === 'report-management') {
        this.showClassManagementSide('course-report')
      }
    },
    activeAssignment(tabName) {
      this.activeAssignmentTab = tabName
      this.viewLesson = false
      this.viewLessonGroup = false
    },
    showLesson(lesson, mode = '') {
      this.viewLesson = true
      this.viewLessonGroup = false
      this.selectedLessonId = lesson.lesson_id
      this.selectedEventId = lesson.event_id
      this.selectedMode = mode
    },
    showClassManagementSide(sideBarValue) {
      if (this.isRouterView) {
        this.classManagementSide = sideBarValue
        this.showMobileSidebar = false
        this.$router.replace({
          name: `${this.self.usertype}-view-students`,
          params: { id: this.classId },
          query: { 'view-tab': sideBarValue },
        })
      } else {
        this.isRouterView = false
        this.activeAssignment('homework')
        this.classManagementSide = sideBarValue
        this.showMobileSidebar = false
        const url = new URL(window.location.href)
        url.searchParams.set('view-tab', sideBarValue)
        window.history.replaceState({}, '', url)
      }
    },
    chartOptions(value) {
      return {
        chart: {
          type: 'radialBar',
          width: '100%', // Chart takes full width of the parent container
          height: 160,
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            hollow: {
              size: '50%',
            },
            dataLabels: {
              name: {
                show: true,
                offsetY: 15, // Adjust label position if necessary
                style: {
                  fontSize: '5px', // Set font size for the label
                },
              },
              value: {
                show: false,
              },
            },
          },
        },
        colors: [this.getColor(value)],
        labels: [`${value?.toFixed(2)}%`],
      }
    },
    getColor(value) {
      if (value >= 25.0 && value <= 50.0) return '#ff9f43' // Warning
      if (value > 50.0 && value <= 75.0) return '#7367f0' // Primary
      if (value > 75.0) return '#28c76f' // Success
      return '#ea5455' // Danger
    },
    profileImageChanged(e) {
      const file = e.target.files[0]
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.accessToken}`,
        },
      }
      const data = new FormData()
      data.append('avatar', file)
      useJwt.axiosIns.post('/api/v1/user/avatar/update', data, config)
        .then(response => {
          this.$root.$emit('userUpdate', response.data.data)
          localStorage.setItem('userData', JSON.stringify(response.data.data))
          this.studentWithStat.find(student => student.id === this.self.id).avatar = response.data.data.avatar
          this.showSuccessMessage(response)
          GEC_EVENT_BUS.$emit('update-avatar', response.data.data.avatar)
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
    },
    toggleSidebar() {
      this.showFullSidebar = !this.showFullSidebar
      localStorage.setItem('showFullSidebar', this.showFullSidebar)
    },
  },
}
</script>
<style scoped lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.disabled {
  color: gray;
  pointer-events: none;
  cursor: default;
}
a.nav-link {
  height: 100%;
}
.bottom-0 {
  bottom: 0;
}
.badge h4 {
  font-size: 14px !important;
}

.top-badge,
.first-badge {
  width: 25px;
  height: 25px;
}

.student-details-card {
  .card-body {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    .student-report-btns {
      .disabled {
        color: #d5d5d5;
      }
    }
  }
}
.badge-row {
  padding: 5px 0px;
  min-height: 25px;
}
.btn-purple {
  background-color: #906acc !important;
  border: #906acc !important;
}
.btn-dark-blue {
  background-color: #8483d2 !important;
  border: #9695c9 !important;
}
.btn-add-rubric {
  background-color: #28c76f !important;
  border: #28c76f !important;
}
.btn-attendance {
  background-color: #d24a8d !important;
  border: #28c76f !important;
}
.btn-student-scoreboard {
  background-color: #5b60c7 !important;
  border: #28c76f !important;
}

.class-stats .title {
  margin-bottom: 4px;
}
.class-stats {
  max-width: 148px;
}
.badge-container {
  display: block;
}
.badge-icon {
  margin: 0 4px;
}
.info-icon {
  position: absolute;
  top: 0;
}
.live-class {
  padding: 0.65rem 0.5rem;
}
.live-class:hover {
  background: rgba(115, 103, 240, 0.12);
  cursor: pointer;
}
.side-card {
  width: 100%;
  height: 80vh;
  transition: all 0.3s ease;
}
.side-card-minimal {
  width: 84px;
}
.dark-layout {
  .info-icon {
    color: #ffe699;
    fill: #333;
  }
  .class-nav,
  .side-card {
    background-color: $theme-dark-card-bg !important;
  }

  a.nav-link:hover,
  a.nav-link.active {
    background-color: $primary !important;
  }
}

/* .nav-item {
  border: 1px solid black;
  height: 30px;
  margin: 2px;
} */

@media screen and (max-width: 576px) {
  .top-row .col {
    padding: 0 !important;
  }
  .top-row .h2 {
    font-size: 18px !important;
  }
  .dates {
    justify-content: space-between;
  }
  .row-2 {
    flex-flow: column-reverse;
  }
  .side-card {
    height: 100%;
  }
}

@media screen and (max-width: 912px) and (min-width: 540px) {
  .stats-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-flow: row;
  }
}

@media screen and (max-width: 1200px) {
  .stats-container {
    // justify-content: flex-end;
    gap: 48px;
  }
}

.ribbon {
  position: absolute;
  margin-left: 5px;
  top: 0px;
  z-index: 1;
  overflow: hidden;
  width: 65px;
  height: 50px;
  text-align: right;
}
.ribbon span {
  font-size: 0.7rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 26px;
  transform: rotate(45deg);
  width: 125px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#9bc90d 0%, #79a70a 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 17px;
  text-indent: -40px;
  right: -29px;
  right: -60px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #79a70a;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}
.ribbon span::after {
  content: "";
  position: absolute;
  right: 0%;
  top: 100%;
  z-index: -1;
  border-right: 3px solid #79a70a;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}

.student-view-card .card-body {
  padding: 0px !important;
}
.stats-container-left {
  background-size: contain;
  background-repeat: no-repeat;
}

.badge-icon {
  height: 40px;
  width: 40px;
}

.p-t-1 {
  padding-top: 10px;
}
.teachers {
  margin-right: 4px;
}
.progress-btn {
  /* width:200px; */
  height:50px;
  border:1px solid black;
  position:relative;
}
.progress-btn:after {
  content:'\A';
  position:absolute;
  background: #6ad08b40;
  top:0; bottom:0;
  left:0;
  transition:all 2s;
  -webkit-transition:all 2s;
}
.clickable-div:hover {
  border: 1.2px solid #0041ff;
}
.action-icon {
  margin-left: 8px;
  cursor: pointer;
  padding: 5px;
  border-radius: 7px ;
}
.action-icon:hover {
  background: rgba(40,199,111,.12);
}

.vertical-progress {
  height: 200px; /* Adjust the height as needed */
  transform: rotate(-90deg);
  transform-origin: top left;
}

.vertical-progress .progress-bar {
  width: 100%;
}

.gradient-background {
  background: linear-gradient(to right, #9ecdb0, #d2f5bd);
}

.dark-layout .gradient-background {
  background: #283046;
}
.scroll-content {
  height: 522px;
}
.class-nav {
  a.nav-link {
    border-radius: 0;
  }
  a.nav-link:hover {
    background-color: #1c6697;
    color: #fff;
  }
  a.nav-link.active {
    background-color: #1c6697;
    color: #fff;
    border: none;
  }
}
</style>
<style lang="scss">
// new ui class
.students-detail-card {
  .student-no-avatar {
    position: relative;
    width: 70px;
  }
  .student-avatar {
    width: 70px !important;
    height: 70px !important;
  }

  .student-details-card {
  /*min-height: 255px !important;*/
    h4 {
        font-size: 14px;
      }
      p{
        font-size:12px;
      }
      h5{
        font-size:13px;
      }
    }
}
.courses-tabs {
 .nav-item{
  margin-bottom:5px;
  a.nav-link{
    padding:5px
  }
 }
}
.assignment-filters {
  .nav-item {
    a.nav-link{
      padding: 12px 10px;
      span.badge{
        margin-left:10px;
      }
    }
  }
  .course-card {
    .card-body {
      padding: 1rem 0.6rem;
    }
    &:hover {
      box-shadow: 0 8px 25px -8px #82868b;
    }
  }
}
.assignment-tab {
    margin-bottom: 5px;
    .btn {
      border-radius: 23px;
      padding: 7px;
      font-size: 13px;
      margin-right: 8px;
    }
  }
  .class-side-section, .student-side-section {
    padding: 10px;
  }

@media screen and (max-width: 576px) {
  .side-classname {
    justify-content: space-between;
  }
  .assignment-filters {
    .nav-item {
      a.nav-link{
        padding: 8px;
        span.badge{
          margin-left:5px;
        }
      }
    }
  }
  .assignment-tab, .classMgm-tab {
    margin-bottom: 5px;
    .btn {
      padding: 10px;
      font-size: 12px;
    }
  }
  .class-nav a.nav-link {
    padding: 0 14px !important;
  }
}

@media screen and (max-width: 375px) {
  .class-nav a.nav-link {
    padding: 0 8px !important;
  }
}

@media screen and (max-width: 740px) {

  .student-table-nav {
    .student-nav {
      margin-top: 5px;
    }
  }
  /*
  Enter and leave animations can use different
  durations and timing functions.
*/
}
@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}
.blinking-icon {
  font-size: 50px;
  animation: blink 1s infinite;
}
</style>
